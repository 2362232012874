@import url(./assets/fonts/stylesheet.css);
.load {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  background: #FFFFFF;
}
.load:before,
.load:after,
.load .dot,
.load .outline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.load .dot {
  width: 96px;
  height: 96px;
  background: #FFFFFF;
  animation: in 4s linear infinite;
  z-index: 2;
}
.load:before {
  content: "";
  width: 0;
  height: 0;
  background: #28e1b9;
  animation: out1 4s linear infinite;
}
.load:after {
  content: "";
  width: 0;
  height: 0;
  background: #FFFFFF;
  animation: out2 4s linear infinite;
}
.load .outline {
  width: 120px;
  height: 120px;
  z-index: 2;
}
.load .outline span {
  width: 68px;
  height: 68px;
  transform-origin: 100% 100%;
  transform: rotate(45deg) skewX(80deg);
  overflow: hidden;
  position: absolute;
  bottom: 50%;
  right: 50%;
  animation: outline 4s linear infinite;
}
.load .outline span:before {
  content: "";
  display: block;
  border: solid 5px #FFFFFF;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  transform: skewX(-80deg);
  animation: outlineBefore 4s linear infinite;
}
@keyframes outline {
  0% {
    transform: rotate(0deg) skewX(80deg);
  }
  25% {
    transform: rotate(500deg) skewX(15deg);
  }
  50% {
    transform: rotate(1000deg) skewX(40deg);
  }
  75% {
    transform: rotate(1500deg) skewX(60deg);
  }
  100% {
    transform: rotate(2160deg) skewX(80deg);
  }
}
@keyframes outlineBefore {
  0% {
    transform: skewX(-80deg);
    border: solid 5px #FFFFFF;
  }
  25% {
    transform: skewX(-15deg);
    border: solid 5px #FFFFFF;
  }
  49% {
    border: solid 5px #FFFFFF;
  }
  50% {
    transform: skewX(-40deg);
    border: solid 5px #28e1b9;
  }
  75% {
    transform: skewX(-60deg);
    border: solid 5px #28e1b9;
  }
  100% {
    transform: skewX(-80deg);
    border: solid 5px #28e1b9;
  }
}
@keyframes in {
  0% {
    width: 144px;
    height: 144px;
    background: #FFFFFF;
  }
  40% {
    width: 0;
    height: 0;
    background: #FFFFFF;
  }
  41% {
    width: 0;
    height: 0;
    background: #28e1b9;
  }
  50% {
    width: 144px;
    height: 144px;
    background: #28e1b9;
  }
  90% {
    width: 0;
    height: 0;
    background: #28e1b9;
  }
  91% {
    width: 0;
    height: 0;
    background: #FFFFFF;
  }
  100% {
    width: 144px;
    height: 144px;
    background: #FFFFFF;
  }
}
@keyframes out1 {
  0% {
    width: 0;
    height: 0;
  }
  30% {
    width: 120vw;
    height: 120vw;
  }
  100% {
    width: 120vw;
    height: 120vw;
  }
}
@keyframes out2 {
  0% {
    width: 0;
    height: 0;
  }
  30% {
    width: 0;
    height: 0;
  }
  60% {
    width: 120vw;
    height: 120vw;
  }
  100% {
    width: 120vw;
    height: 120vw;
  }
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'EuclidCircularA-Regular', sans-serif;
  background: #d6dade;
  color: #000;
  font-size: 16px;
  cursor: none;
  overflow: hidden;
}
::selection {
  background: #28e1b9;
}
.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group > button.mapboxgl-ctrl-compass {
  cursor: none;
}
.cursor {
  position: absolute;
  line-height: 0;
  background: rgba(40, 225, 185, 0.4);
  z-index: 99999999999;
  pointer-events: none;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: padding 0.3s ease;
}
.cursor.hover {
  padding: 16px;
}
.cursor.hover span {
  width: 13px;
  height: 13px;
  background: #000;
}
.cursor span {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #5a5a5a;
  transition: all 0.3s ease;
}
img {
  border: 0;
}
h1,
.h1 {
  font-size: 55px;
  line-height: 69px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-family: 'EuclidCircularA-Medium', sans-serif;
}
h2,
.h2 {
  font-size: 60px;
  font-weight: normal;
  margin: 0 0 40px;
  padding: 0;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  text-transform: lowercase;
}
h3,
.h3 {
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 15px;
  padding: 0;
}
input,
textarea,
a,
button,
select {
  outline: none;
}
a {
  color: #000;
  cursor: none;
  transition: all 0.3s ease;
}
a:hover {
  color: #000;
  text-decoration: none;
}
b,
strong {
  font-family: 'EuclidCircularA-Medium', sans-serif;
  font-weight: normal;
}
.clr {
  clear: both;
}
.currentMarker {
  background-image: url(./assets/images/dot.svg);
  background-size: cover;
  width: 99px;
  height: 99px;
  border-radius: 50%;
}
#app.openHeader header {
  top: 50px;
  right: 110px;
}
#app.openHeader .hamburger-header {
  margin-top: 6px;
}
#app.openHeader .hamburger-header span {
  transform: rotate(45deg);
}
#app.openHeader .hamburger-header span:last-child {
  transform: rotate(-45deg);
  margin-top: -2px;
}
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.turn {
  display: none;
}
.page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  transition: opacity 0.5s ease;
}
.page-Home {
  background: no-repeat center / cover;
}
.page-Home .content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 138px;
  position: relative;
  z-index: 1;
}
.loaded .m10,
.loaded .m25,
.loaded .m50,
.loaded .m75,
.loaded .m100,
.loaded .m300 {
  will-change: transform;
  transform: translateX(0);
}
.triangle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 180px 309px 0 0;
  border-color: #28e1b9 transparent transparent transparent;
}
.triangle .logo {
  width: 167px;
  height: 24px;
  transform: rotate(-30deg);
  position: absolute;
  bottom: 87px;
  left: 22px;
}
.logo {
  width: 258px;
  height: 38px;
  display: inline-block;
}
.delimobil-logo {
  background: url(./assets/images/logo.svg) no-repeat 50% / contain;
}
.anytime-logo {
  background: url('../public/img/by/anytimelogo.svg') no-repeat 50% / contain;
}
.content {
  width: 1784px;
  margin: 0 auto;
}
.m10 {
  transform: translateX(10%);
  transition: transform 1s ease;
}
.m25 {
  transform: translateX(25%);
  transition: transform 1.3s ease;
}
.m50 {
  transform: translateX(50%);
  transition: transform 1.6s ease;
}
.m75 {
  transform: translateX(75%);
  transition: transform 1.9s ease;
}
.m100 {
  transform: translateX(100%);
  transition: transform 2.2s ease;
}
.m300 {
  transform: translateX(300%);
  transition: transform 2.5s ease;
}
header {
  position: fixed;
  top: 50px;
  right: 52px;
  z-index: 99;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}
header a {
  font-size: 18px;
  line-height: 18px;
  height: 50px;
  border-radius: 25px;
  text-decoration: none;
  padding: 15px 18px 0;
  margin: 0 1px;
  font-family: 'EuclidCircularA-Semibold', sans-serif;
  transition: all 0.3s ease;
}
header a:hover,
header a.active {
  text-decoration: none;
  color: #8e9296;
  background: #e6e9ec;
}
header.hidden {
  top: -100px;
}
footer {
  height: auto;
  background: #e0e2e3;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  transition: all 0.3s ease;
}
footer .bg {
  position: absolute;
  height: 400%;
  width: 100%;
  background: white;
  left: 0;
  top: 0;
  z-index: -1;
}
footer > div {
  display: flex;
  height: 100%;
  align-items: center;
}
footer > div > div {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0;
}
footer a {
  color: #000;
  text-decoration: none;
  margin: 0 15px;
  display: inline-block;
}
footer a:hover,
footer a.active {
  text-decoration: underline;
}
footer.hidden {
  transform: translateY(100%);
}
.medium {
  font-family: 'EuclidCircularA-Medium', sans-serif;
}
.semibold {
  font-family: 'EuclidCircularA-Semibold', sans-serif;
}
.policy {
  width: calc(100% - 16px);
  border-radius: 40px;
  height: 90px;
  background: #28e1b9;
  color: black;
  font-size: inherit;
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px auto 10px 8px;
  animation-duration: 2s;
  animation-name: slidein;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
@keyframes slidein {
  0% {
    transform: translateX(45%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.policy.mobile {
  flex-direction: column;
  font-size: 3.59375vw;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 999;
}
.policy.mobile .but {
  padding: 15px 0;
  width: calc(100% - 50px);
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 400px) {
  .policy.mobile .but {
    height: 30px;
    padding: 7px 0;
  }
}
.policy .policy-text {
  font-size: inherit;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.policy .policy-text div {
  width: 100%;
}
.policy .but {
  border-radius: 40px;
  background: black;
  color: #28e1b9;
  text-align: center;
  padding: 0 115px;
  cursor: pointer;
  user-select: none;
  height: 50px;
  margin-right: 20px;
}
.policy .link {
  text-decoration: underline;
  margin: 0;
}
.menu-footer {
  background: #e8e8e8;
  padding: 0;
  position: relative;
}
.menu-footer .bg {
  background: #e8e8e8;
}
.menu-footer a {
  padding: 6px 14px 0;
  text-decoration: none;
  margin: 0 1px;
  height: 34px;
  border-radius: 17px;
}
.menu-footer a:hover,
.menu-footer a.active {
  background: #28e1b9;
  text-decoration: none;
}
.menu-additional {
  background: #d2d5d9;
  padding: 0;
  font-family: 'EuclidCircularA-Semibold', sans-serif;
  height: 4.58333333vw;
  position: relative;
}
.menu-additional .bg {
  background: #d2d5d9;
}
.menu-additional a {
  padding: 13px 28px 0;
  text-decoration: none;
  margin: 0 1px;
  height: 48px;
  border-radius: 24px;
}
.menu-additional a:hover,
.menu-additional a.active {
  background: #e5e6e8;
  text-decoration: none;
}
.btn {
  font-size: 38px;
  line-height: 38px;
  background: #28e1b9;
  text-transform: lowercase;
  border-radius: 43px;
  text-decoration: none;
  padding: 20px 45px 28px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  display: inline-block;
  color: #000;
  text-align: center;
  transition: all 0.3s ease;
}
.btn-small {
  font-size: 18px;
  line-height: 18px;
  border-radius: 22px;
  text-align: center;
  padding: 12px 20px 13px;
}
.btn-midi {
  font-size: 17px;
  line-height: 17px;
  border-radius: 27px;
  padding: 18px 30px 19px;
}
.btn-fixed {
  width: 270px;
}
.btn-large {
  font-size: 20px;
  border-radius: 27px;
  padding: 7px 30px 10px;
}
.btn .icon {
  margin-left: -5px;
  margin-right: 20px;
  vertical-align: middle;
}
.btn:hover {
  background: #fff;
}
.btn:hover.btn-hover-gray {
  background-color: #f9f9f9;
}
.icon {
  display: inline-block;
  background: no-repeat center / contain;
}
.icon-tg {
  background-image: url(./assets/images/icon_tg-2.svg);
  width: 17px;
  height: 14px;
}
.background-video-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 1340px;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (orientation: landscape) and (min-width: 1340px) {
  .background-video-image {
    background-size: cover;
  }
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #d6d9de;
  display: flex;
  align-items: center;
  justify-content: center;
}
.background-video .screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  opacity: 0;
  transition: opacity 1s ease;
}
.background-video .screen.active {
  opacity: 1;
}
.dn {
  display: none;
}
.download {
  position: fixed;
  z-index: 11;
  bottom: 137px;
  right: 68px;
  opacity: 1;
  transition: all 1.5s ease;
}
.download-left {
  right: 100%!important;
  transform: translateX(100%);
}
.download-hide {
  right: 110%!important;
}
.number {
  position: absolute;
  color: #fff;
  opacity: 0.4;
  font-size: 1363px;
  line-height: normal;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-45%);
  font-family: 'EuclidCircularA-Medium', sans-serif;
}
.pagination {
  position: absolute;
  bottom: 137px;
  left: 50%;
  display: flex;
  z-index: 11;
  height: 155px;
  align-items: center;
  margin-left: 75px;
}
.pagination__item {
  font-size: 25px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  display: inline-block;
  border-radius: 15px;
  width: 38px;
  text-align: center;
  margin-right: 5px;
  transition: all 0.3s ease;
}
.pagination__item.active,
.pagination__item:hover {
  background: #28e1b9;
}
.navigation {
  position: absolute;
  bottom: 137px;
  right: 68px;
  display: flex;
  z-index: 11;
  background: #28e1b9;
  border-radius: 50px;
  transition: all 1.5s ease;
}
.prev,
.next {
  display: inline-block;
  width: 155px;
  height: 155px;
  background: #28e1b9 url(./assets/images/arrow.svg) no-repeat center;
  border-radius: 0 50px 50px 0;
  transition: all 0.3s ease;
}
.prev.disable,
.next.disable {
  background-image: url(./assets/images/arrow-green-light.svg);
}
.prev {
  transform: rotate(180deg);
  background-color: #4ef2ce;
}
.prev.disable {
  background-image: url(./assets/images/arrow-green.svg);
}
.title {
  font-size: 110px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  margin-bottom: 40px;
}
.title-big {
  font-size: 150px;
}
.title-verybig {
  font-size: 160px;
}
.text {
  font-size: 26px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
}
p {
  padding: 0;
  margin: 0;
}
p + p {
  margin-top: 20px;
}
.steps-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 76px;
  width: 50%;
  margin-left: 50%;
  position: relative;
  padding-bottom: 10%;
}
.social {
  display: flex;
  margin-right: 15px;
}
.social__icon {
  display: inline-block;
  width: 44px;
  height: 34px;
  background: #fff no-repeat center;
  border-radius: 17px;
  margin: 0 8px 0 0;
  transition: all 0.3s ease;
}
.social__icon-fb {
  background-image: url(./assets/images/icon_fb.svg);
}
.social__icon-tw {
  background-image: url(./assets/images/icon_tw.svg);
}
.social__icon-youtube {
  background-image: url(./assets/images/icon_youtube.svg);
}
.social__icon-inst {
  background-image: url(./assets/images/icon_inst.svg);
}
.social__icon-vk {
  background-image: url(./assets/images/icon_vk.svg);
}
.social__icon-tg {
  background-image: url(./assets/images/icon_tg.svg);
}
.social__icon:hover {
  background-color: #28e1b9;
}
.app-screen {
  position: absolute;
  right: 50%;
  bottom: 0;
  width: 50%;
  background: no-repeat right center / contain;
  height: 80%;
}
.app-screen.m50 {
  left: -10%;
  top: -4%;
}
.vue-map-container {
  position: absolute!important;
  top: 0;
  left: 0;
}
.map-controls {
  position: absolute;
  z-index: 9;
  display: flex;
  bottom: 68px;
  left: 68px;
}
.map-controls__zoom {
  width: 94px;
  height: 94px;
  background: #4ef2ce;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  line-height: 26px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  text-decoration: none;
  transition: all 0.3s ease;
}
.map-controls__zoom-plus {
  border-radius: 0 50% 50% 0;
  background: #28e1b9;
}
.map-controls__zoom-minus {
  border-radius: 50% 0 0 50%;
}
.filter {
  position: absolute;
  z-index: 9;
  top: 147px;
  right: 68px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  padding: 27px 25px 25px;
  border-radius: 43px;
  min-width: 400px;
}
.filter-tab + .filter-tab {
  padding-top: 25px;
}
.carousel-block {
  background: #82e9d3;
  margin: 0 -25px -25px;
  border-radius: 0 0 43px 43px;
  overflow: hidden;
  height: 230px;
  max-width: 400px;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}
.owl-stage {
  width: 99999px;
}
.owl-carousel-tariffs {
  display: none;
}
.owl-carousel-tariffs .owl-stage {
  padding-left: 0!important;
}
.owl-carousel-tariffs.active {
  display: block;
}
.owl-carousel-tariffs .owl-carousel {
  transition: opacity 0.3s ease;
}
.owl-carousel-popup .owl-stage-outer {
  margin-bottom: -60px;
  padding-bottom: 60px;
  margin-top: -45px;
  padding-top: 45px;
}
.tariff {
  margin: 22px 0 21px 20px;
  background: #fff;
  border-radius: 30px;
  padding: 18px 10px 10px;
  font-size: 15px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  height: 186px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tariff .btn {
  width: 100%;
}
.tariff__content {
  padding: 0 13px;
}
.tariff__price {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 14px;
}
.tariff__wait {
  font-family: 'EuclidCircularA-Semibold', sans-serif;
  margin-bottom: 5px;
}
.tariff__free {
  color: #626262;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tariff-data {
  margin-bottom: -15px;
}
.tariff-data__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.tariff-data__row > div:last-child {
  font-family: 'EuclidCircularA-Semibold', sans-serif;
}
.block {
  font-size: 20px;
  padding: 40px 70px 35px;
}
.block__title {
  font-size: 40px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  margin-bottom: 20px;
}
.block-white {
  background: #fff;
}
.car-names {
  margin: 7px 0 0 0;
  max-height: 200px;
}
.car-name {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 8px;
  align-items: center;
}
.car-name__name {
  padding: 7px 27px 8px;
  text-decoration: none;
  color: #393939;
  border-radius: 21px;
  transition: all 0.3s ease;
}
.car-name__name:hover {
  text-decoration: none;
  color: #393939;
}
.car-name__cnt {
  opacity: 0.5;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  font-size: 16px;
}
.car-name:hover .car-name__name,
.car-name.active .car-name__name {
  background: #f9f9f9;
}
.cars {
  background: #f3fffc;
  position: relative;
  height: 93px;
}
.cars:before,
.cars:after {
  content: "";
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  width: 52px;
  height: 100%;
}
.cars:before {
  background: #d7fbf3;
}
.cars:after {
  width: 0;
  left: 52px;
  height: 0;
  border-style: solid;
  border-width: 93px 0 0 203px;
  border-color: transparent transparent transparent #d7fbf3;
}
.cars-block {
  overflow: hidden;
  padding-top: 95px;
  margin: 0 -25px;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.car {
  width: 95%;
  height: 163px;
  background: no-repeat center / contain;
  position: absolute;
  bottom: 2px;
  /*left: 100%;*/
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}
.mgl-map-wrapper,
.mapboxgl-map {
  width: 100%;
  height: 100%;
}
.marker-btn {
  display: none;
}
.fancybox-content {
  background: none;
  padding: 0;
}
.fancybox-bg {
  background: #d6dade;
}
.fancybox-is-open .fancybox-bg {
  opacity: 0.7;
}
.fancybox-close {
  background: url(./assets/images/close.svg) no-repeat center / contain;
  width: 28px;
  height: 28px;
  top: 35px;
  right: 48px;
  position: absolute;
  display: inline-block;
  z-index: 1;
}
.fancybox-close svg {
  display: none;
}
.fancybox-slide--html .fancybox-close-small {
  padding: 0;
  top: 35px;
  right: 48px;
}
.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
  cursor: none;
}
.popup {
  overflow: hidden;
  border-radius: 60px;
  background: #fff;
  width: 930px;
  position: relative;
}
.popup__tabs {
  display: flex;
}
.popup__tab {
  width: 50%;
  padding: 10px 70px 35px;
  height: 130px;
  font-size: 40px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  text-transform: lowercase;
  background: #f1f1f1;
  text-decoration: none;
}
.popup__tab.active {
  background: #fff;
  /*border-bottom: 1px solid @green2;*/
}
.popup__tab__content {
  display: none;
  font-size: 22px;
  padding: 45px 70px 160px;
}
.popup__tab__content.active {
  display: block;
}
.popup__text {
  position: relative;
}
.popup__text-big {
  font-size: 25px;
}
.popup__btn {
  display: none;
}
.popup__title {
  font-size: 30px;
}
.popup__content {
  position: relative;
}
.popup__content-blocks {
  background: #d6dade;
  padding: 0!important;
}
.popup__number {
  position: absolute;
  font-size: 490px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  line-height: 310px;
  top: 0;
  right: -25px;
  color: #f1f1f1;
}
.popup__next,
.popup__prev {
  height: 160px;
  display: flex;
  position: absolute;
  bottom: 0;
  color: #000;
  font-size: 25px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  width: 50%;
  align-items: center;
  padding: 60px 75px 0;
  text-decoration: none;
}
.popup__next:before,
.popup__prev:before {
  content: "";
  background: #fff;
  width: 100%;
  height: 60px;
  display: inline-block;
  bottom: 100px;
  position: absolute;
  left: 0;
}
.popup__next:after,
.popup__prev:after {
  content: "";
  background: no-repeat center / contain;
  width: 22px;
  height: 39px;
  position: absolute;
  bottom: 33px;
}
.popup__next {
  left: 50%;
  background: #28e1b9;
  color: #4ef2ce;
}
.popup__next:hover {
  color: #4ef2ce;
}
.popup__next:before {
  border-radius: 0 0 60px 0;
}
.popup__next:after {
  background-image: url(./assets/images/arrow-green-light.svg);
  right: 70px;
}
.popup__prev {
  left: 0;
  background: #4df0cc;
  color: #28e1b9;
  justify-content: flex-end;
}
.popup__prev:hover {
  color: #28e1b9;
}
.popup__prev:before {
  border-radius: 0 0 0 60px;
}
.popup__prev:after {
  background-image: url(./assets/images/arrow-green.svg);
  left: 70px;
  transform: rotate(180deg);
}
.carouselPrevActive .popup__prev {
  color: #000;
}
.carouselPrevActive .popup__prev:after {
  background-image: url(./assets/images/arrow.svg);
}
.carouselNextActive .popup__next {
  color: #000;
}
.carouselNextActive .popup__next:after {
  background-image: url(./assets/images/arrow.svg);
}
.half {
  display: flex;
  align-items: center;
  height: 100%;
}
.half__text {
  width: 50%;
  padding-right: 50px;
}
.half__pic {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background: no-repeat center / cover;
}
.half-reverse .half__text {
  margin-left: 50%;
  padding-right: 0;
  padding-left: 75px;
}
.half-reverse .half__pic {
  left: 0;
}
.half-about .half__text {
  font-family: 'EuclidCircularA-Light', sans-serif;
  font-size: 58px;
}
.half-about .half__text-small {
  font-size: 43px;
}
.half-about .scroll-area,
.half-about .magic-area {
  max-height: 70vh;
}
.half-members .scroll-area {
  max-height: 60vh;
}
.half-members .half__text {
  font-size: 26px;
  padding-bottom: 150px;
}
.half-contacts .scroll-area {
  max-height: 43vh;
}
.half-contacts .half__text {
  font-size: 22px;
}
.half-press .scroll-area {
  max-height: 57vh;
}
.half-press .half__text {
  height: 57vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 190px;
}
.half-article .scroll-area {
  max-height: 71vh;
}
.half-article .half__text {
  padding-bottom: 30px;
}
.vertical-tabs {
  margin-left: -18px;
  margin-bottom: 30px;
}
.vertical-tabs__content {
  height: 450px;
  position: relative;
}
.vertical-tab {
  font-family: 'EuclidCircularA-Medium', sans-serif;
  font-size: 26px;
  padding: 4px 18px 5px;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 20px;
  transition: all 0.3s ease;
}
.vertical-tab.active,
.vertical-tab:hover {
  background: #fff;
}
.vertical-tab__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.members {
  position: absolute;
  bottom: 88px;
  left: 50%;
  z-index: 10;
  line-height: 0;
  white-space: nowrap;
}
.members .scroll-area {
  max-width: 50vw;
}
.member {
  width: 138px;
  height: 138px;
  display: inline-block;
  position: relative;
}
.member__pic {
  background: no-repeat center / cover;
  width: 100%;
  height: 100%;
}
.member__voile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}
.member__voile:hover {
  background: rgba(40, 225, 185, 0.6);
}
.member__name {
  font-size: 46px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  margin-bottom: 30px;
}
.ps__scrollbar-y-rail {
  background-color: transparent!important;
}
.ps__scrollbar-x-rail {
  background-color: transparent!important;
}
.ps__container {
  padding-right: 10px;
}
.ps__scrollbar-y,
.ps__scrollbar-x {
  background: #fff !important;
  z-index: 1;
}
.ps.psAlways .ps__scrollbar-x-rail {
  opacity: 1!important;
}
.ps.psAlways .ps__scrollbar-y-rail {
  opacity: 1!important;
}
.ps.psGreen .ps__scrollbar-y,
.ps.psGreen .ps__scrollbar-x {
  background: #28e1b9 !important;
}
.ps:hover .ps__scrollbar-x-rail {
  opacity: 1!important;
}
.ps:hover .ps__scrollbar-y-rail {
  opacity: 1!important;
}
.hamburger {
  transition: all 1s ease;
}
.hamburger-header {
  display: block;
  position: absolute;
  line-height: 0;
  z-index: 999;
  top: 68px;
  right: 68px;
  width: 28px;
}
.hamburger-header span {
  display: block;
  width: 100%;
  background: #000;
  height: 3px;
  transition: all 0.3s ease;
}
.hamburger-header span:last-child {
  margin-top: 11px;
}
.hamburger-header.hidden {
  top: -100px;
}
.contacts-block {
  margin-top: 55px;
}
.contacts-block__btns .btn + .btn {
  margin-left: 15px;
}
.contacts-block__btns-2 {
  margin-left: -20px;
  margin-top: -25px;
}
.contacts-block__btns-2 .btn {
  margin: 25px 0 0 20px;
}
.contacts-block__btns-2 .btn + .btn {
  margin-left: 20px;
}
.contacts-block__title {
  margin-bottom: 50px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
}
.contacts-block__title b {
  font-family: 'EuclidCircularA-Bold', sans-serif;
}
.contacts-block__text-small {
  font-size: 18px;
  line-height: 30px;
}
.articles {
  margin-left: -16px;
}
.article__link {
  font-size: 60px;
  line-height: 55px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  margin-bottom: 40px;
  padding: 8px 16px 13px;
  transition: background 0.3s ease;
}
.article__link:hover {
  background: #28e1b9;
}
.article__link a {
  text-decoration: none;
  transition: none;
}
.article__link a:hover {
  text-decoration: none;
}
.article img {
  max-width: 100%;
}
.article li + li {
  margin-top: 15px;
}
.voile {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  background: rgba(40, 225, 185, 0.8);
  width: 100%;
  height: 100%;
  z-index: 98;
  justify-content: center;
  align-items: center;
}
.voile.full {
  background: #28e1b9;
}
.article {
  font-size: 22px;
}
.article__title {
  font-size: 46px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  margin-bottom: 30px;
}
.back {
  position: absolute;
  left: 50%;
  margin-left: 75px;
  text-decoration: none;
  font-size: 25px;
  font-family: 'EuclidCircularA-Medium', sans-serif;
  top: 60px;
}
.pages {
  display: none;
}
.ps.ps--active-y {
  margin-right: -20px;
  padding-right: 20px;
  position: relative;
}
.hideDesktop {
  display: none;
}
body {
  font-size: 0.83333333vw;
}
/*.cursor {
    &.hover {
      padding: 16 / 1920 * 100vw * @factor;

      span {
        width: 13 / 1920 * 100vw * @factor;
        height: 13 / 1920 * 100vw * @factor;
      }
    }

    span {
      width: 14 / 1920 * 100vw * @factor;
      height: 14 / 1920 * 100vw * @factor;
    }
  }*/
h1,
.h1 {
  font-size: 2.86458333vw;
  line-height: 3.59375vw;
}
h2,
.h2 {
  font-size: 3.125vw;
  margin: 0 0 2.08333333vw;
}
h3,
.h3 {
  font-size: 1.875vw;
  margin: 0 0 1.875vw;
}
.currentMarker {
  /*width: 99 / 1920 * 100vw * @factor;
    height: 99 / 1920 * 100vw * @factor;*/
}
#app.openHeader header {
  top: 2.60416667vw;
  right: 5.72916667vw;
}
#app.openHeader .hamburger-header {
  margin-top: 0.3125vw;
}
#app.openHeader .hamburger-header span:last-child {
  margin-top: -0.10416667vw;
}
.page-Home .content {
  padding-bottom: 7.1875vw;
}
.triangle {
  border-width: 9.375vw 16.09375vw 0 0;
}
.triangle .logo {
  width: 8.69791667vw;
  height: 1.25vw;
  bottom: 4.53125vw;
  left: 1.14583333vw;
}
.logo {
  width: 13.4375vw;
  height: 1.97916667vw;
}
.content {
  width: 92.91666667vw;
}
header {
  top: 2.60416667vw;
  right: 2.70833333vw;
}
header a {
  font-size: 0.9375vw;
  line-height: 0.9375vw;
  height: 2.60416667vw;
  border-radius: 1.30208333vw;
  padding: 0.78125vw 0.9375vw 0;
  margin: 0 0.05208333vw;
}
header.hidden {
  top: -5.20833333vw;
}
footer {
  font-size: 0.83333333vw;
}
footer a {
  margin: 0 0.78125vw;
}
.menu-footer a {
  padding: 0.3125vw 0.72916667vw 0;
  margin: 0 0.05208333vw;
  height: 1.77083333vw;
  border-radius: 0.88541667vw;
}
.menu-additional {
  padding: 0 4.16666667vw 0 2.08333333vw;
  padding: 0;
}
.menu-additional a {
  padding: 0.67708333vw 1.45833333vw 0;
  margin: 0 0.05208333vw;
  height: 2.5vw;
  border-radius: 1.25vw;
}
.btn {
  font-size: 1.97916667vw;
  line-height: 1.97916667vw;
  border-radius: 2.23958333vw;
  padding: 1.04166667vw 2.34375vw 1.45833333vw;
}
.btn-small {
  font-size: 0.9375vw;
  line-height: 0.9375vw;
  border-radius: 1.14583333vw;
  padding: 0.625vw 1.04166667vw 0.67708333vw;
}
.btn-midi {
  font-size: 0.88541667vw;
  line-height: 0.88541667vw;
  border-radius: 1.40625vw;
  padding: 0.9375vw 1.5625vw 0.98958333vw;
}
.btn-fixed {
  width: 14.0625vw;
}
.btn-large {
  font-size: 1.04166667vw;
  border-radius: 1.40625vw;
  padding: 0.36458333vw 1.5625vw 0.52083333vw;
}
.btn .icon {
  margin-left: -0.26041667vw;
  margin-right: 1.04166667vw;
  vertical-align: middle;
}
.icon-tg {
  width: 0.88541667vw;
  height: 0.72916667vw;
}
.download {
  bottom: 7.13541667vw;
  right: 3.54166667vw;
}
.download-left {
  margin-right: -3.54166667vw;
}
.number {
  font-size: 70.98958333vw;
}
.pagination {
  bottom: 7.13541667vw;
  height: 8.07291667vw;
  margin-left: 3.90625vw;
}
.pagination__item {
  font-size: 1.30208333vw;
  border-radius: 0.78125vw;
  width: 1.97916667vw;
  margin-right: 0.26041667vw;
}
.navigation {
  bottom: 7.13541667vw;
  right: 3.54166667vw;
  border-radius: 2.60416667vw;
}
.prev,
.next {
  width: 8.07291667vw;
  height: 8.07291667vw;
  background-size: 1.30208333vw auto;
  border-radius: 0 2.60416667vw 2.60416667vw 0;
}
.title {
  font-size: 5.72916667vw;
  margin-bottom: 2.08333333vw;
}
.title-big {
  font-size: 7.8125vw;
}
.title-verybig {
  font-size: 8.33333333vw;
}
.text {
  font-size: 1.35416667vw;
}
p + p {
  margin-top: 1.04166667vw;
}
.steps-page {
  padding-left: 3.95833333vw;
}
.social {
  margin-right: 0.78125vw;
  margin-left: 0.78125vw;
}
.social__icon {
  width: 2.29166667vw;
  height: 1.77083333vw;
  background-size: 0.72916667vw 0.72916667vw;
  border-radius: 0.88541667vw;
  margin: 0 0.41666667vw 0 0;
}
.map-controls {
  bottom: 3.54166667vw;
  left: 3.54166667vw;
}
.map-controls__zoom {
  width: 4.89583333vw;
  height: 4.89583333vw;
  font-size: 1.35416667vw;
  line-height: 1.35416667vw;
}
.filter {
  top: 7.65625vw;
  right: 2.70833333vw;
  box-shadow: 0 0 1.04166667vw rgba(0, 0, 0, 0.04);
  padding: 1.40625vw 1.30208333vw 1.30208333vw;
  border-radius: 2.23958333vw;
  min-width: 20.83333333vw;
}
.filter-tab + .filter-tab {
  padding-top: 1.30208333vw;
}
.carousel-block {
  margin: 0 -1.30208333vw -1.30208333vw;
  border-radius: 0 0 2.23958333vw 2.23958333vw;
  height: 11.97916667vw;
  max-width: 20.83333333vw;
}
.owl-carousel-popup .owl-stage-outer {
  margin-bottom: -3.125vw;
  padding-bottom: 3.125vw;
  margin-top: -2.34375vw;
  padding-top: 2.34375vw;
}
.block {
  font-size: 1.04166667vw;
  padding: 2.08333333vw 3.64583333vw 1.82291667vw;
}
.block__title {
  font-size: 2.08333333vw;
  margin-bottom: 1.04166667vw;
}
.tariff {
  margin: 1.14583333vw 0 1.09375vw 1.04166667vw;
  border-radius: 1.5625vw;
  padding: 0.9375vw 0.52083333vw 0.52083333vw;
  font-size: 0.78125vw;
  height: 9.6875vw;
}
.tariff__content {
  padding: 0 0.67708333vw;
}
.tariff__price {
  font-size: 1.14583333vw;
  margin-bottom: 0.72916667vw;
}
.tariff__wait {
  margin-bottom: 0.26041667vw;
}
.car-names {
  margin: 0.36458333vw 0 0 0;
  max-height: 10.41666667vw;
}
.car-name {
  font-size: 1.04166667vw;
  margin-right: 1.04166667vw;
  margin-bottom: 0.41666667vw;
}
.car-name__name {
  padding: 0.36458333vw 1.40625vw 0.41666667vw;
  border-radius: 1.09375vw;
}
.car-name__cnt {
  font-size: 0.83333333vw;
}
.cars {
  height: 4.84375vw;
}
.cars:before,
.cars:after {
  width: 2.70833333vw;
}
.cars:after {
  left: 2.70833333vw;
  border-width: 4.84375vw 0 0 10.57291667vw;
}
.cars-block {
  padding-top: 4.94791667vw;
  margin: 0 -1.30208333vw;
}
.car {
  height: 8.48958333vw;
  bottom: 1.30208333vw;
}
.fancybox-close {
  width: 1.45833333vw;
  height: 1.45833333vw;
  top: 1.82291667vw;
  right: 2.5vw;
}
.fancybox-slide--html .fancybox-close-small {
  top: 1.82291667vw;
  right: 2.5vw;
}
.popup {
  border-radius: 3.125vw;
  width: 100%;
}
.popup__tab {
  padding: 2.60416667vw 3.64583333vw 0.52083333vw;
  height: 6.77083333vw;
  font-size: 2.08333333vw;
}
.popup__tab__content {
  font-size: 1.14583333vw;
  padding: 2.34375vw 3.64583333vw 8.33333333vw;
}
.popup__text-big {
  font-size: 1.30208333vw;
}
.popup__title {
  font-size: 1.5625vw;
}
.popup__number {
  font-size: 25.52083333vw;
  line-height: 16.14583333vw;
  right: -1.30208333vw;
}
.popup__next,
.popup__prev {
  height: 8.33333333vw;
  font-size: 1.30208333vw;
  padding: 3.125vw 3.90625vw 0;
}
.popup__next:before,
.popup__prev:before {
  height: 3.125vw;
  bottom: 5.20833333vw;
}
.popup__next:after,
.popup__prev:after {
  width: 1.14583333vw;
  height: 2.03125vw;
  bottom: 1.71875vw;
}
.popup__next:before {
  border-radius: 0 0 3.125vw 0;
}
.popup__next:after {
  right: 3.64583333vw;
}
.popup__prev:before {
  border-radius: 0 0 0 3.125vw;
}
.popup__prev:after {
  left: 3.64583333vw;
}
.half__text {
  padding-right: 2.60416667vw;
}
.half-reverse .half__text {
  padding-left: 3.90625vw;
}
.half-about .half__text {
  font-size: 3.02083333vw;
}
.half-about .half__text-small {
  font-size: 2.23958333vw;
}
.half-members .half__text {
  font-size: 1.35416667vw;
  padding-bottom: 7.8125vw;
}
.half-contacts .half__text {
  font-size: 1.14583333vw;
}
.half-press .half__text {
  margin-bottom: 9.89583333vw;
}
.half-article .half__text {
  padding-bottom: 1.5625vw;
}
.vertical-tabs {
  margin-left: -0.9375vw;
  margin-bottom: 1.5625vw;
}
.vertical-tabs__content {
  height: 23.4375vw;
}
.vertical-tab {
  font-size: 1.35416667vw;
  padding: 0.20833333vw 0.9375vw 0.26041667vw;
  margin-bottom: 0.52083333vw;
  border-radius: 1.04166667vw;
}
.members {
  bottom: 4.58333333vw;
}
.member {
  width: 7.1875vw;
  height: 7.1875vw;
}
.member__name {
  font-size: 2.39583333vw;
  margin-bottom: 1.5625vw;
}
.ps__container {
  padding-right: 0.52083333vw;
}
.hamburger-header {
  top: 3.54166667vw;
  right: 3.54166667vw;
  width: 1.45833333vw;
}
.hamburger-header span:last-child {
  margin-top: 0.57291667vw;
}
.hamburger-header.hidden {
  top: -5.20833333vw;
}
.contacts-block {
  margin-top: 2.86458333vw;
}
.contacts-block__btns .btn + .btn {
  margin-left: 0.78125vw;
}
.contacts-block__btns-2 {
  margin-left: -1.04166667vw;
  margin-top: -1.30208333vw;
}
.contacts-block__btns-2 .btn {
  margin: 1.30208333vw 0 0 1.04166667vw;
}
.contacts-block__btns-2 .btn + .btn {
  margin-left: 1.04166667vw;
}
.contacts-block__title {
  margin-bottom: 2.60416667vw;
}
.contacts-block__text-small {
  font-size: 0.9375vw;
  line-height: 1.5625vw;
}
.articles {
  margin-left: -0.83333333vw;
}
.article {
  font-size: 1.14583333vw;
}
.article__link {
  font-size: 3.125vw;
  line-height: 2.86458333vw;
  margin-bottom: 2.08333333vw;
  padding: 0.41666667vw 0.83333333vw 0.67708333vw;
}
.article__title {
  font-size: 2.39583333vw;
  margin-bottom: 1.5625vw;
}
.back {
  margin-left: 3.90625vw;
  font-size: 1.30208333vw;
  top: 3.125vw;
}
.ps.ps--active-y {
  margin-right: -1.04166667vw;
  padding-right: 1.04166667vw;
}
@media screen and (min-aspect-ratio: 20/10) and (orientation: landscape) and (min-width: 768px) {
  body {
    font-size: 0.70833333vw;
  }
  /*.cursor {
    &.hover {
      padding: 16 / 1920 * 100vw * @factor;

      span {
        width: 13 / 1920 * 100vw * @factor;
        height: 13 / 1920 * 100vw * @factor;
      }
    }

    span {
      width: 14 / 1920 * 100vw * @factor;
      height: 14 / 1920 * 100vw * @factor;
    }
  }*/
  h1,
  .h1 {
    font-size: 2.43489583vw;
    line-height: 3.0546875vw;
  }
  h2,
  .h2 {
    font-size: 2.65625vw;
    margin: 0 0 1.77083333vw;
  }
  h3,
  .h3 {
    font-size: 1.59375vw;
    margin: 0 0 1.59375vw;
  }
  .currentMarker {
    /*width: 99 / 1920 * 100vw * @factor;
    height: 99 / 1920 * 100vw * @factor;*/
  }
  #app.openHeader header {
    top: 2.21354167vw;
    right: 4.86979167vw;
  }
  #app.openHeader .hamburger-header {
    margin-top: 0.265625vw;
  }
  #app.openHeader .hamburger-header span:last-child {
    margin-top: -0.08854167vw;
  }
  .page-Home .content {
    padding-bottom: 6.109375vw;
  }
  .triangle {
    border-width: 7.96875vw 13.6796875vw 0 0;
  }
  .triangle .logo {
    width: 7.39322917vw;
    height: 1.0625vw;
    bottom: 3.8515625vw;
    left: 0.97395833vw;
  }
  .logo {
    width: 11.421875vw;
    height: 1.68229167vw;
  }
  .content {
    width: 78.97916667vw;
  }
  header {
    top: 2.21354167vw;
    right: 2.30208333vw;
  }
  header a {
    font-size: 0.796875vw;
    line-height: 0.796875vw;
    height: 2.21354167vw;
    border-radius: 1.10677083vw;
    padding: 0.6640625vw 0.796875vw 0;
    margin: 0 0.04427083vw;
  }
  header.hidden {
    top: -4.42708333vw;
  }
  footer {
    font-size: 0.70833333vw;
  }
  footer a {
    margin: 0 0.6640625vw;
  }
  .menu-footer a {
    padding: 0.265625vw 0.61979167vw 0;
    margin: 0 0.04427083vw;
    height: 1.50520833vw;
    border-radius: 0.75260417vw;
  }
  .menu-additional {
    padding: 0 3.54166667vw 0 1.77083333vw;
    padding: 0;
  }
  .menu-additional a {
    padding: 0.57552083vw 1.23958333vw 0;
    margin: 0 0.04427083vw;
    height: 2.125vw;
    border-radius: 1.0625vw;
  }
  .btn {
    font-size: 1.68229167vw;
    line-height: 1.68229167vw;
    border-radius: 1.90364583vw;
    padding: 0.88541667vw 1.9921875vw 1.23958333vw;
  }
  .btn-small {
    font-size: 0.796875vw;
    line-height: 0.796875vw;
    border-radius: 0.97395833vw;
    padding: 0.53125vw 0.88541667vw 0.57552083vw;
  }
  .btn-midi {
    font-size: 0.75260417vw;
    line-height: 0.75260417vw;
    border-radius: 1.1953125vw;
    padding: 0.796875vw 1.328125vw 0.84114583vw;
  }
  .btn-fixed {
    width: 11.953125vw;
  }
  .btn-large {
    font-size: 0.88541667vw;
    border-radius: 1.1953125vw;
    padding: 0.30989583vw 1.328125vw 0.44270833vw;
  }
  .btn .icon {
    margin-left: -0.22135417vw;
    margin-right: 0.88541667vw;
    vertical-align: middle;
  }
  .icon-tg {
    width: 0.75260417vw;
    height: 0.61979167vw;
  }
  .download {
    bottom: 6.06510417vw;
    right: 3.01041667vw;
  }
  .download-left {
    margin-right: -3.01041667vw;
  }
  .number {
    font-size: 60.34114583vw;
  }
  .pagination {
    bottom: 6.06510417vw;
    height: 6.86197917vw;
    margin-left: 3.3203125vw;
  }
  .pagination__item {
    font-size: 1.10677083vw;
    border-radius: 0.6640625vw;
    width: 1.68229167vw;
    margin-right: 0.22135417vw;
  }
  .navigation {
    bottom: 6.06510417vw;
    right: 3.01041667vw;
    border-radius: 2.21354167vw;
  }
  .prev,
  .next {
    width: 6.86197917vw;
    height: 6.86197917vw;
    background-size: 1.10677083vw auto;
    border-radius: 0 2.21354167vw 2.21354167vw 0;
  }
  .title {
    font-size: 4.86979167vw;
    margin-bottom: 1.77083333vw;
  }
  .title-big {
    font-size: 6.640625vw;
  }
  .title-verybig {
    font-size: 7.08333333vw;
  }
  .text {
    font-size: 1.15104167vw;
  }
  p + p {
    margin-top: 0.88541667vw;
  }
  .steps-page {
    padding-left: 3.36458333vw;
  }
  .social {
    margin-right: 0.6640625vw;
    margin-left: 0.6640625vw;
  }
  .social__icon {
    width: 1.94791667vw;
    height: 1.50520833vw;
    background-size: 0.61979167vw 0.61979167vw;
    border-radius: 0.75260417vw;
    margin: 0 0.35416667vw 0 0;
  }
  .map-controls {
    bottom: 3.01041667vw;
    left: 3.01041667vw;
  }
  .map-controls__zoom {
    width: 4.16145833vw;
    height: 4.16145833vw;
    font-size: 1.15104167vw;
    line-height: 1.15104167vw;
  }
  .filter {
    top: 6.5078125vw;
    right: 2.30208333vw;
    box-shadow: 0 0 0.88541667vw rgba(0, 0, 0, 0.04);
    padding: 1.1953125vw 1.10677083vw 1.10677083vw;
    border-radius: 1.90364583vw;
    min-width: 17.70833333vw;
  }
  .filter-tab + .filter-tab {
    padding-top: 1.10677083vw;
  }
  .carousel-block {
    margin: 0 -1.10677083vw -1.10677083vw;
    border-radius: 0 0 1.90364583vw 1.90364583vw;
    height: 10.18229167vw;
    max-width: 17.70833333vw;
  }
  .owl-carousel-popup .owl-stage-outer {
    margin-bottom: -2.65625vw;
    padding-bottom: 2.65625vw;
    margin-top: -1.9921875vw;
    padding-top: 1.9921875vw;
  }
  .block {
    font-size: 0.88541667vw;
    padding: 1.77083333vw 3.09895833vw 1.54947917vw;
  }
  .block__title {
    font-size: 1.77083333vw;
    margin-bottom: 0.88541667vw;
  }
  .tariff {
    margin: 0.97395833vw 0 0.9296875vw 0.88541667vw;
    border-radius: 1.328125vw;
    padding: 0.796875vw 0.44270833vw 0.44270833vw;
    font-size: 0.6640625vw;
    height: 8.234375vw;
  }
  .tariff__content {
    padding: 0 0.57552083vw;
  }
  .tariff__price {
    font-size: 0.97395833vw;
    margin-bottom: 0.61979167vw;
  }
  .tariff__wait {
    margin-bottom: 0.22135417vw;
  }
  .car-names {
    margin: 0.30989583vw 0 0 0;
    max-height: 8.85416667vw;
  }
  .car-name {
    font-size: 0.88541667vw;
    margin-right: 0.88541667vw;
    margin-bottom: 0.35416667vw;
  }
  .car-name__name {
    padding: 0.30989583vw 1.1953125vw 0.35416667vw;
    border-radius: 0.9296875vw;
  }
  .car-name__cnt {
    font-size: 0.70833333vw;
  }
  .cars {
    height: 4.1171875vw;
  }
  .cars:before,
  .cars:after {
    width: 2.30208333vw;
  }
  .cars:after {
    left: 2.30208333vw;
    border-width: 4.1171875vw 0 0 8.98697917vw;
  }
  .cars-block {
    padding-top: 4.20572917vw;
    margin: 0 -1.10677083vw;
  }
  .car {
    height: 7.21614583vw;
    bottom: 1.10677083vw;
  }
  .fancybox-close {
    width: 1.23958333vw;
    height: 1.23958333vw;
    top: 1.54947917vw;
    right: 2.125vw;
  }
  .fancybox-slide--html .fancybox-close-small {
    top: 1.54947917vw;
    right: 2.125vw;
  }
  .popup {
    border-radius: 2.65625vw;
    width: 100%;
  }
  .popup__tab {
    padding: 2.21354167vw 3.09895833vw 0.44270833vw;
    height: 5.75520833vw;
    font-size: 1.77083333vw;
  }
  .popup__tab__content {
    font-size: 0.97395833vw;
    padding: 1.9921875vw 3.09895833vw 7.08333333vw;
  }
  .popup__text-big {
    font-size: 1.10677083vw;
  }
  .popup__title {
    font-size: 1.328125vw;
  }
  .popup__number {
    font-size: 21.69270833vw;
    line-height: 13.72395833vw;
    right: -1.10677083vw;
  }
  .popup__next,
  .popup__prev {
    height: 7.08333333vw;
    font-size: 1.10677083vw;
    padding: 2.65625vw 3.3203125vw 0;
  }
  .popup__next:before,
  .popup__prev:before {
    height: 2.65625vw;
    bottom: 4.42708333vw;
  }
  .popup__next:after,
  .popup__prev:after {
    width: 0.97395833vw;
    height: 1.7265625vw;
    bottom: 1.4609375vw;
  }
  .popup__next:before {
    border-radius: 0 0 2.65625vw 0;
  }
  .popup__next:after {
    right: 3.09895833vw;
  }
  .popup__prev:before {
    border-radius: 0 0 0 2.65625vw;
  }
  .popup__prev:after {
    left: 3.09895833vw;
  }
  .half__text {
    padding-right: 2.21354167vw;
  }
  .half-reverse .half__text {
    padding-left: 3.3203125vw;
  }
  .half-about .half__text {
    font-size: 2.56770833vw;
  }
  .half-about .half__text-small {
    font-size: 1.90364583vw;
  }
  .half-members .half__text {
    font-size: 1.15104167vw;
    padding-bottom: 6.640625vw;
  }
  .half-contacts .half__text {
    font-size: 0.97395833vw;
  }
  .half-press .half__text {
    margin-bottom: 8.41145833vw;
  }
  .half-article .half__text {
    padding-bottom: 1.328125vw;
  }
  .vertical-tabs {
    margin-left: -0.796875vw;
    margin-bottom: 1.328125vw;
  }
  .vertical-tabs__content {
    height: 19.921875vw;
  }
  .vertical-tab {
    font-size: 1.15104167vw;
    padding: 0.17708333vw 0.796875vw 0.22135417vw;
    margin-bottom: 0.44270833vw;
    border-radius: 0.88541667vw;
  }
  .members {
    bottom: 3.89583333vw;
  }
  .member {
    width: 6.109375vw;
    height: 6.109375vw;
  }
  .member__name {
    font-size: 2.03645833vw;
    margin-bottom: 1.328125vw;
  }
  .ps__container {
    padding-right: 0.44270833vw;
  }
  .hamburger-header {
    top: 3.01041667vw;
    right: 3.01041667vw;
    width: 1.23958333vw;
  }
  .hamburger-header span:last-child {
    margin-top: 0.48697917vw;
  }
  .hamburger-header.hidden {
    top: -4.42708333vw;
  }
  .contacts-block {
    margin-top: 2.43489583vw;
  }
  .contacts-block__btns .btn + .btn {
    margin-left: 0.6640625vw;
  }
  .contacts-block__btns-2 {
    margin-left: -0.88541667vw;
    margin-top: -1.10677083vw;
  }
  .contacts-block__btns-2 .btn {
    margin: 1.10677083vw 0 0 0.88541667vw;
  }
  .contacts-block__btns-2 .btn + .btn {
    margin-left: 0.88541667vw;
  }
  .contacts-block__title {
    margin-bottom: 2.21354167vw;
  }
  .contacts-block__text-small {
    font-size: 0.796875vw;
    line-height: 1.328125vw;
  }
  .articles {
    margin-left: -0.70833333vw;
  }
  .article {
    font-size: 0.97395833vw;
  }
  .article__link {
    font-size: 2.65625vw;
    line-height: 2.43489583vw;
    margin-bottom: 1.77083333vw;
    padding: 0.35416667vw 0.70833333vw 0.57552083vw;
  }
  .article__title {
    font-size: 2.03645833vw;
    margin-bottom: 1.328125vw;
  }
  .back {
    margin-left: 3.3203125vw;
    font-size: 1.10677083vw;
    top: 2.65625vw;
  }
  .ps.ps--active-y {
    margin-right: -0.88541667vw;
    padding-right: 0.88541667vw;
  }
  .car-names {
    height: 6.86197917vw;
  }
}
@media screen and (min-aspect-ratio: 25/10) and (orientation: landscape) and (min-width: 768px) {
  body {
    font-size: 0.66666667vw;
  }
  /*.cursor {
    &.hover {
      padding: 16 / 1920 * 100vw * @factor;

      span {
        width: 13 / 1920 * 100vw * @factor;
        height: 13 / 1920 * 100vw * @factor;
      }
    }

    span {
      width: 14 / 1920 * 100vw * @factor;
      height: 14 / 1920 * 100vw * @factor;
    }
  }*/
  h1,
  .h1 {
    font-size: 2.29166667vw;
    line-height: 2.875vw;
  }
  h2,
  .h2 {
    font-size: 2.5vw;
    margin: 0 0 1.66666667vw;
  }
  h3,
  .h3 {
    font-size: 1.5vw;
    margin: 0 0 1.5vw;
  }
  .currentMarker {
    /*width: 99 / 1920 * 100vw * @factor;
    height: 99 / 1920 * 100vw * @factor;*/
  }
  #app.openHeader header {
    top: 2.08333333vw;
    right: 4.58333333vw;
  }
  #app.openHeader .hamburger-header {
    margin-top: 0.25vw;
  }
  #app.openHeader .hamburger-header span:last-child {
    margin-top: -0.08333333vw;
  }
  .page-Home .content {
    padding-bottom: 5.75vw;
  }
  .triangle {
    border-width: 7.5vw 12.875vw 0 0;
  }
  .triangle .logo {
    width: 6.95833333vw;
    height: 1vw;
    bottom: 3.625vw;
    left: 0.91666667vw;
  }
  .logo {
    width: 10.75vw;
    height: 1.58333333vw;
  }
  .content {
    width: 74.33333333vw;
  }
  header {
    top: 2.08333333vw;
    right: 2.16666667vw;
  }
  header a {
    font-size: 0.75vw;
    line-height: 0.75vw;
    height: 2.08333333vw;
    border-radius: 1.04166667vw;
    padding: 0.625vw 0.75vw 0;
    margin: 0 0.04166667vw;
  }
  header.hidden {
    top: -4.16666667vw;
  }
  footer {
    font-size: 0.66666667vw;
  }
  footer a {
    margin: 0 0.625vw;
  }
  .menu-footer a {
    padding: 0.25vw 0.58333333vw 0;
    margin: 0 0.04166667vw;
    height: 1.41666667vw;
    border-radius: 0.70833333vw;
  }
  .menu-additional {
    padding: 0 3.33333333vw 0 1.66666667vw;
    padding: 0;
  }
  .menu-additional a {
    padding: 0.54166667vw 1.16666667vw 0;
    margin: 0 0.04166667vw;
    height: 2vw;
    border-radius: 1vw;
  }
  .btn {
    font-size: 1.58333333vw;
    line-height: 1.58333333vw;
    border-radius: 1.79166667vw;
    padding: 0.83333333vw 1.875vw 1.16666667vw;
  }
  .btn-small {
    font-size: 0.75vw;
    line-height: 0.75vw;
    border-radius: 0.91666667vw;
    padding: 0.5vw 0.83333333vw 0.54166667vw;
  }
  .btn-midi {
    font-size: 0.70833333vw;
    line-height: 0.70833333vw;
    border-radius: 1.125vw;
    padding: 0.75vw 1.25vw 0.79166667vw;
  }
  .btn-fixed {
    width: 11.25vw;
  }
  .btn-large {
    font-size: 0.83333333vw;
    border-radius: 1.125vw;
    padding: 0.29166667vw 1.25vw 0.41666667vw;
  }
  .btn .icon {
    margin-left: -0.20833333vw;
    margin-right: 0.83333333vw;
    vertical-align: middle;
  }
  .icon-tg {
    width: 0.70833333vw;
    height: 0.58333333vw;
  }
  .download {
    bottom: 5.70833333vw;
    right: 2.83333333vw;
  }
  .download-left {
    margin-right: -2.83333333vw;
  }
  .number {
    font-size: 56.79166667vw;
  }
  .pagination {
    bottom: 5.70833333vw;
    height: 6.45833333vw;
    margin-left: 3.125vw;
  }
  .pagination__item {
    font-size: 1.04166667vw;
    border-radius: 0.625vw;
    width: 1.58333333vw;
    margin-right: 0.20833333vw;
  }
  .navigation {
    bottom: 5.70833333vw;
    right: 2.83333333vw;
    border-radius: 2.08333333vw;
  }
  .prev,
  .next {
    width: 6.45833333vw;
    height: 6.45833333vw;
    background-size: 1.04166667vw auto;
    border-radius: 0 2.08333333vw 2.08333333vw 0;
  }
  .title {
    font-size: 4.58333333vw;
    margin-bottom: 1.66666667vw;
  }
  .title-big {
    font-size: 6.25vw;
  }
  .title-verybig {
    font-size: 6.66666667vw;
  }
  .text {
    font-size: 1.08333333vw;
  }
  p + p {
    margin-top: 0.83333333vw;
  }
  .steps-page {
    padding-left: 3.16666667vw;
  }
  .social {
    margin-right: 0.625vw;
    margin-left: 0.625vw;
  }
  .social__icon {
    width: 1.83333333vw;
    height: 1.41666667vw;
    background-size: 0.58333333vw 0.58333333vw;
    border-radius: 0.70833333vw;
    margin: 0 0.33333333vw 0 0;
  }
  .map-controls {
    bottom: 2.83333333vw;
    left: 2.83333333vw;
  }
  .map-controls__zoom {
    width: 3.91666667vw;
    height: 3.91666667vw;
    font-size: 1.08333333vw;
    line-height: 1.08333333vw;
  }
  .filter {
    top: 6.125vw;
    right: 2.16666667vw;
    box-shadow: 0 0 0.83333333vw rgba(0, 0, 0, 0.04);
    padding: 1.125vw 1.04166667vw 1.04166667vw;
    border-radius: 1.79166667vw;
    min-width: 16.66666667vw;
  }
  .filter-tab + .filter-tab {
    padding-top: 1.04166667vw;
  }
  .carousel-block {
    margin: 0 -1.04166667vw -1.04166667vw;
    border-radius: 0 0 1.79166667vw 1.79166667vw;
    height: 9.58333333vw;
    max-width: 16.66666667vw;
  }
  .owl-carousel-popup .owl-stage-outer {
    margin-bottom: -2.5vw;
    padding-bottom: 2.5vw;
    margin-top: -1.875vw;
    padding-top: 1.875vw;
  }
  .block {
    font-size: 0.83333333vw;
    padding: 1.66666667vw 2.91666667vw 1.45833333vw;
  }
  .block__title {
    font-size: 1.66666667vw;
    margin-bottom: 0.83333333vw;
  }
  .tariff {
    margin: 0.91666667vw 0 0.875vw 0.83333333vw;
    border-radius: 1.25vw;
    padding: 0.75vw 0.41666667vw 0.41666667vw;
    font-size: 0.625vw;
    height: 7.75vw;
  }
  .tariff__content {
    padding: 0 0.54166667vw;
  }
  .tariff__price {
    font-size: 0.91666667vw;
    margin-bottom: 0.58333333vw;
  }
  .tariff__wait {
    margin-bottom: 0.20833333vw;
  }
  .car-names {
    margin: 0.29166667vw 0 0 0;
    max-height: 8.33333333vw;
  }
  .car-name {
    font-size: 0.83333333vw;
    margin-right: 0.83333333vw;
    margin-bottom: 0.33333333vw;
  }
  .car-name__name {
    padding: 0.29166667vw 1.125vw 0.33333333vw;
    border-radius: 0.875vw;
  }
  .car-name__cnt {
    font-size: 0.66666667vw;
  }
  .cars {
    height: 3.875vw;
  }
  .cars:before,
  .cars:after {
    width: 2.16666667vw;
  }
  .cars:after {
    left: 2.16666667vw;
    border-width: 3.875vw 0 0 8.45833333vw;
  }
  .cars-block {
    padding-top: 3.95833333vw;
    margin: 0 -1.04166667vw;
  }
  .car {
    height: 6.79166667vw;
    bottom: 1.04166667vw;
  }
  .fancybox-close {
    width: 1.16666667vw;
    height: 1.16666667vw;
    top: 1.45833333vw;
    right: 2vw;
  }
  .fancybox-slide--html .fancybox-close-small {
    top: 1.45833333vw;
    right: 2vw;
  }
  .popup {
    border-radius: 2.5vw;
    width: 100%;
  }
  .popup__tab {
    padding: 2.08333333vw 2.91666667vw 0.41666667vw;
    height: 5.41666667vw;
    font-size: 1.66666667vw;
  }
  .popup__tab__content {
    font-size: 0.91666667vw;
    padding: 1.875vw 2.91666667vw 6.66666667vw;
  }
  .popup__text-big {
    font-size: 1.04166667vw;
  }
  .popup__title {
    font-size: 1.25vw;
  }
  .popup__number {
    font-size: 20.41666667vw;
    line-height: 12.91666667vw;
    right: -1.04166667vw;
  }
  .popup__next,
  .popup__prev {
    height: 6.66666667vw;
    font-size: 1.04166667vw;
    padding: 2.5vw 3.125vw 0;
  }
  .popup__next:before,
  .popup__prev:before {
    height: 2.5vw;
    bottom: 4.16666667vw;
  }
  .popup__next:after,
  .popup__prev:after {
    width: 0.91666667vw;
    height: 1.625vw;
    bottom: 1.375vw;
  }
  .popup__next:before {
    border-radius: 0 0 2.5vw 0;
  }
  .popup__next:after {
    right: 2.91666667vw;
  }
  .popup__prev:before {
    border-radius: 0 0 0 2.5vw;
  }
  .popup__prev:after {
    left: 2.91666667vw;
  }
  .half__text {
    padding-right: 2.08333333vw;
  }
  .half-reverse .half__text {
    padding-left: 3.125vw;
  }
  .half-about .half__text {
    font-size: 2.41666667vw;
  }
  .half-about .half__text-small {
    font-size: 1.79166667vw;
  }
  .half-members .half__text {
    font-size: 1.08333333vw;
    padding-bottom: 6.25vw;
  }
  .half-contacts .half__text {
    font-size: 0.91666667vw;
  }
  .half-press .half__text {
    margin-bottom: 7.91666667vw;
  }
  .half-article .half__text {
    padding-bottom: 1.25vw;
  }
  .vertical-tabs {
    margin-left: -0.75vw;
    margin-bottom: 1.25vw;
  }
  .vertical-tabs__content {
    height: 18.75vw;
  }
  .vertical-tab {
    font-size: 1.08333333vw;
    padding: 0.16666667vw 0.75vw 0.20833333vw;
    margin-bottom: 0.41666667vw;
    border-radius: 0.83333333vw;
  }
  .members {
    bottom: 3.66666667vw;
  }
  .member {
    width: 5.75vw;
    height: 5.75vw;
  }
  .member__name {
    font-size: 1.91666667vw;
    margin-bottom: 1.25vw;
  }
  .ps__container {
    padding-right: 0.41666667vw;
  }
  .hamburger-header {
    top: 2.83333333vw;
    right: 2.83333333vw;
    width: 1.16666667vw;
  }
  .hamburger-header span:last-child {
    margin-top: 0.45833333vw;
  }
  .hamburger-header.hidden {
    top: -4.16666667vw;
  }
  .contacts-block {
    margin-top: 2.29166667vw;
  }
  .contacts-block__btns .btn + .btn {
    margin-left: 0.625vw;
  }
  .contacts-block__btns-2 {
    margin-left: -0.83333333vw;
    margin-top: -1.04166667vw;
  }
  .contacts-block__btns-2 .btn {
    margin: 1.04166667vw 0 0 0.83333333vw;
  }
  .contacts-block__btns-2 .btn + .btn {
    margin-left: 0.83333333vw;
  }
  .contacts-block__title {
    margin-bottom: 2.08333333vw;
  }
  .contacts-block__text-small {
    font-size: 0.75vw;
    line-height: 1.25vw;
  }
  .articles {
    margin-left: -0.66666667vw;
  }
  .article {
    font-size: 0.91666667vw;
  }
  .article__link {
    font-size: 2.5vw;
    line-height: 2.29166667vw;
    margin-bottom: 1.66666667vw;
    padding: 0.33333333vw 0.66666667vw 0.54166667vw;
  }
  .article__title {
    font-size: 1.91666667vw;
    margin-bottom: 1.25vw;
  }
  .back {
    margin-left: 3.125vw;
    font-size: 1.04166667vw;
    top: 2.5vw;
  }
  .ps.ps--active-y {
    margin-right: -0.83333333vw;
    padding-right: 0.83333333vw;
  }
  .car-names {
    height: 4.375vw;
  }
}
@media screen and (min-aspect-ratio: 30/10) and (orientation: landscape) and (min-width: 768px) {
  body {
    font-size: 0.625vw;
  }
  /*.cursor {
    &.hover {
      padding: 16 / 1920 * 100vw * @factor;

      span {
        width: 13 / 1920 * 100vw * @factor;
        height: 13 / 1920 * 100vw * @factor;
      }
    }

    span {
      width: 14 / 1920 * 100vw * @factor;
      height: 14 / 1920 * 100vw * @factor;
    }
  }*/
  h1,
  .h1 {
    font-size: 2.1484375vw;
    line-height: 2.6953125vw;
  }
  h2,
  .h2 {
    font-size: 2.34375vw;
    margin: 0 0 1.5625vw;
  }
  h3,
  .h3 {
    font-size: 1.40625vw;
    margin: 0 0 1.40625vw;
  }
  .currentMarker {
    /*width: 99 / 1920 * 100vw * @factor;
    height: 99 / 1920 * 100vw * @factor;*/
  }
  #app.openHeader header {
    top: 1.953125vw;
    right: 4.296875vw;
  }
  #app.openHeader .hamburger-header {
    margin-top: 0.234375vw;
  }
  #app.openHeader .hamburger-header span:last-child {
    margin-top: -0.078125vw;
  }
  .page-Home .content {
    padding-bottom: 5.390625vw;
  }
  .triangle {
    border-width: 7.03125vw 12.0703125vw 0 0;
  }
  .triangle .logo {
    width: 6.5234375vw;
    height: 0.9375vw;
    bottom: 3.3984375vw;
    left: 0.859375vw;
  }
  .logo {
    width: 10.078125vw;
    height: 1.484375vw;
  }
  .content {
    width: 69.6875vw;
  }
  header {
    top: 1.953125vw;
    right: 2.03125vw;
  }
  header a {
    font-size: 0.703125vw;
    line-height: 0.703125vw;
    height: 1.953125vw;
    border-radius: 0.9765625vw;
    padding: 0.5859375vw 0.703125vw 0;
    margin: 0 0.0390625vw;
  }
  header.hidden {
    top: -3.90625vw;
  }
  footer {
    font-size: 0.625vw;
  }
  footer a {
    margin: 0 0.5859375vw;
  }
  .menu-footer a {
    padding: 0.234375vw 0.546875vw 0;
    margin: 0 0.0390625vw;
    height: 1.328125vw;
    border-radius: 0.6640625vw;
  }
  .menu-additional {
    padding: 0 3.125vw 0 1.5625vw;
    padding: 0;
  }
  .menu-additional a {
    padding: 0.5078125vw 1.09375vw 0;
    margin: 0 0.0390625vw;
    height: 1.875vw;
    border-radius: 0.9375vw;
  }
  .btn {
    font-size: 1.484375vw;
    line-height: 1.484375vw;
    border-radius: 1.6796875vw;
    padding: 0.78125vw 1.7578125vw 1.09375vw;
  }
  .btn-small {
    font-size: 0.703125vw;
    line-height: 0.703125vw;
    border-radius: 0.859375vw;
    padding: 0.46875vw 0.78125vw 0.5078125vw;
  }
  .btn-midi {
    font-size: 0.6640625vw;
    line-height: 0.6640625vw;
    border-radius: 1.0546875vw;
    padding: 0.703125vw 1.171875vw 0.7421875vw;
  }
  .btn-fixed {
    width: 10.546875vw;
  }
  .btn-large {
    font-size: 0.78125vw;
    border-radius: 1.0546875vw;
    padding: 0.2734375vw 1.171875vw 0.390625vw;
  }
  .btn .icon {
    margin-left: -0.1953125vw;
    margin-right: 0.78125vw;
    vertical-align: middle;
  }
  .icon-tg {
    width: 0.6640625vw;
    height: 0.546875vw;
  }
  .download {
    bottom: 5.3515625vw;
    right: 2.65625vw;
  }
  .download-left {
    margin-right: -2.65625vw;
  }
  .number {
    font-size: 53.2421875vw;
  }
  .pagination {
    bottom: 5.3515625vw;
    height: 6.0546875vw;
    margin-left: 2.9296875vw;
  }
  .pagination__item {
    font-size: 0.9765625vw;
    border-radius: 0.5859375vw;
    width: 1.484375vw;
    margin-right: 0.1953125vw;
  }
  .navigation {
    bottom: 5.3515625vw;
    right: 2.65625vw;
    border-radius: 1.953125vw;
  }
  .prev,
  .next {
    width: 6.0546875vw;
    height: 6.0546875vw;
    background-size: 0.9765625vw auto;
    border-radius: 0 1.953125vw 1.953125vw 0;
  }
  .title {
    font-size: 4.296875vw;
    margin-bottom: 1.5625vw;
  }
  .title-big {
    font-size: 5.859375vw;
  }
  .title-verybig {
    font-size: 6.25vw;
  }
  .text {
    font-size: 1.015625vw;
  }
  p + p {
    margin-top: 0.78125vw;
  }
  .steps-page {
    padding-left: 2.96875vw;
  }
  .social {
    margin-right: 0.5859375vw;
    margin-left: 0.5859375vw;
  }
  .social__icon {
    width: 1.71875vw;
    height: 1.328125vw;
    background-size: 0.546875vw 0.546875vw;
    border-radius: 0.6640625vw;
    margin: 0 0.3125vw 0 0;
  }
  .map-controls {
    bottom: 2.65625vw;
    left: 2.65625vw;
  }
  .map-controls__zoom {
    width: 3.671875vw;
    height: 3.671875vw;
    font-size: 1.015625vw;
    line-height: 1.015625vw;
  }
  .filter {
    top: 5.7421875vw;
    right: 2.03125vw;
    box-shadow: 0 0 0.78125vw rgba(0, 0, 0, 0.04);
    padding: 1.0546875vw 0.9765625vw 0.9765625vw;
    border-radius: 1.6796875vw;
    min-width: 15.625vw;
  }
  .filter-tab + .filter-tab {
    padding-top: 0.9765625vw;
  }
  .carousel-block {
    margin: 0 -0.9765625vw -0.9765625vw;
    border-radius: 0 0 1.6796875vw 1.6796875vw;
    height: 8.984375vw;
    max-width: 15.625vw;
  }
  .owl-carousel-popup .owl-stage-outer {
    margin-bottom: -2.34375vw;
    padding-bottom: 2.34375vw;
    margin-top: -1.7578125vw;
    padding-top: 1.7578125vw;
  }
  .block {
    font-size: 0.78125vw;
    padding: 1.5625vw 2.734375vw 1.3671875vw;
  }
  .block__title {
    font-size: 1.5625vw;
    margin-bottom: 0.78125vw;
  }
  .tariff {
    margin: 0.859375vw 0 0.8203125vw 0.78125vw;
    border-radius: 1.171875vw;
    padding: 0.703125vw 0.390625vw 0.390625vw;
    font-size: 0.5859375vw;
    height: 7.265625vw;
  }
  .tariff__content {
    padding: 0 0.5078125vw;
  }
  .tariff__price {
    font-size: 0.859375vw;
    margin-bottom: 0.546875vw;
  }
  .tariff__wait {
    margin-bottom: 0.1953125vw;
  }
  .car-names {
    margin: 0.2734375vw 0 0 0;
    max-height: 7.8125vw;
  }
  .car-name {
    font-size: 0.78125vw;
    margin-right: 0.78125vw;
    margin-bottom: 0.3125vw;
  }
  .car-name__name {
    padding: 0.2734375vw 1.0546875vw 0.3125vw;
    border-radius: 0.8203125vw;
  }
  .car-name__cnt {
    font-size: 0.625vw;
  }
  .cars {
    height: 3.6328125vw;
  }
  .cars:before,
  .cars:after {
    width: 2.03125vw;
  }
  .cars:after {
    left: 2.03125vw;
    border-width: 3.6328125vw 0 0 7.9296875vw;
  }
  .cars-block {
    padding-top: 3.7109375vw;
    margin: 0 -0.9765625vw;
  }
  .car {
    height: 6.3671875vw;
    bottom: 0.9765625vw;
  }
  .fancybox-close {
    width: 1.09375vw;
    height: 1.09375vw;
    top: 1.3671875vw;
    right: 1.875vw;
  }
  .fancybox-slide--html .fancybox-close-small {
    top: 1.3671875vw;
    right: 1.875vw;
  }
  .popup {
    border-radius: 2.34375vw;
    width: 100%;
  }
  .popup__tab {
    padding: 1.953125vw 2.734375vw 0.390625vw;
    height: 5.078125vw;
    font-size: 1.5625vw;
  }
  .popup__tab__content {
    font-size: 0.859375vw;
    padding: 1.7578125vw 2.734375vw 6.25vw;
  }
  .popup__text-big {
    font-size: 0.9765625vw;
  }
  .popup__title {
    font-size: 1.171875vw;
  }
  .popup__number {
    font-size: 19.140625vw;
    line-height: 12.109375vw;
    right: -0.9765625vw;
  }
  .popup__next,
  .popup__prev {
    height: 6.25vw;
    font-size: 0.9765625vw;
    padding: 2.34375vw 2.9296875vw 0;
  }
  .popup__next:before,
  .popup__prev:before {
    height: 2.34375vw;
    bottom: 3.90625vw;
  }
  .popup__next:after,
  .popup__prev:after {
    width: 0.859375vw;
    height: 1.5234375vw;
    bottom: 1.2890625vw;
  }
  .popup__next:before {
    border-radius: 0 0 2.34375vw 0;
  }
  .popup__next:after {
    right: 2.734375vw;
  }
  .popup__prev:before {
    border-radius: 0 0 0 2.34375vw;
  }
  .popup__prev:after {
    left: 2.734375vw;
  }
  .half__text {
    padding-right: 1.953125vw;
  }
  .half-reverse .half__text {
    padding-left: 2.9296875vw;
  }
  .half-about .half__text {
    font-size: 2.265625vw;
  }
  .half-about .half__text-small {
    font-size: 1.6796875vw;
  }
  .half-members .half__text {
    font-size: 1.015625vw;
    padding-bottom: 5.859375vw;
  }
  .half-contacts .half__text {
    font-size: 0.859375vw;
  }
  .half-press .half__text {
    margin-bottom: 7.421875vw;
  }
  .half-article .half__text {
    padding-bottom: 1.171875vw;
  }
  .vertical-tabs {
    margin-left: -0.703125vw;
    margin-bottom: 1.171875vw;
  }
  .vertical-tabs__content {
    height: 17.578125vw;
  }
  .vertical-tab {
    font-size: 1.015625vw;
    padding: 0.15625vw 0.703125vw 0.1953125vw;
    margin-bottom: 0.390625vw;
    border-radius: 0.78125vw;
  }
  .members {
    bottom: 3.4375vw;
  }
  .member {
    width: 5.390625vw;
    height: 5.390625vw;
  }
  .member__name {
    font-size: 1.796875vw;
    margin-bottom: 1.171875vw;
  }
  .ps__container {
    padding-right: 0.390625vw;
  }
  .hamburger-header {
    top: 2.65625vw;
    right: 2.65625vw;
    width: 1.09375vw;
  }
  .hamburger-header span:last-child {
    margin-top: 0.4296875vw;
  }
  .hamburger-header.hidden {
    top: -3.90625vw;
  }
  .contacts-block {
    margin-top: 2.1484375vw;
  }
  .contacts-block__btns .btn + .btn {
    margin-left: 0.5859375vw;
  }
  .contacts-block__btns-2 {
    margin-left: -0.78125vw;
    margin-top: -0.9765625vw;
  }
  .contacts-block__btns-2 .btn {
    margin: 0.9765625vw 0 0 0.78125vw;
  }
  .contacts-block__btns-2 .btn + .btn {
    margin-left: 0.78125vw;
  }
  .contacts-block__title {
    margin-bottom: 1.953125vw;
  }
  .contacts-block__text-small {
    font-size: 0.703125vw;
    line-height: 1.171875vw;
  }
  .articles {
    margin-left: -0.625vw;
  }
  .article {
    font-size: 0.859375vw;
  }
  .article__link {
    font-size: 2.34375vw;
    line-height: 2.1484375vw;
    margin-bottom: 1.5625vw;
    padding: 0.3125vw 0.625vw 0.5078125vw;
  }
  .article__title {
    font-size: 1.796875vw;
    margin-bottom: 1.171875vw;
  }
  .back {
    margin-left: 2.9296875vw;
    font-size: 0.9765625vw;
    top: 2.34375vw;
  }
  .ps.ps--active-y {
    margin-right: -0.78125vw;
    padding-right: 0.78125vw;
  }
  .car-names {
    height: 4.1015625vw;
  }
}
/*@media only screen and (max-width: 1919px), only screen and (max-height: 1079px) {

}*/
@media screen and (max-width: 767px) {
  .hideMobile {
    display: none!important;
  }
  .hideDesktop {
    display: initial;
  }
  body {
    font-size: 16px;
    -webkit-text-size-adjust: none;
  }
  #app {
    cursor: auto;
  }
  #app.openHeader header {
    top: 0;
  }
  #app.openHeader .page {
    opacity: 0;
  }
  #app.openHeader .footer-mobile {
    transform: translateY(100%);
  }
  #app.openHeader .hamburger-header {
    margin-top: 6px;
  }
  #app.openHeader .hamburger-header span:last-child {
    margin-top: -2px;
  }
  #app.openFooter .page {
    opacity: 0;
  }
  #app.openFooter .hamburger-header {
    top: -50px;
  }
  #app.openFooter footer {
    bottom: 0;
  }
  .mobile-header-menu-parts__part {
    position: absolute;
    transition: all 1.5s ease;
  }
  .mobile-header-menu-parts__part-1 {
    top: -220px;
    left: -400px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 220px 400px 0 0;
    border-color: #fff transparent transparent transparent;
  }
  .mobile-header-menu-parts__part-2 {
    bottom: -75px;
    left: -155px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 75px 0 0 155px;
    border-color: transparent transparent transparent #fff;
  }
  .mobile-header-menu-parts__part-3 {
    bottom: -125px;
    right: -210px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 125px 210px;
    border-color: transparent transparent #28e1b9 transparent;
  }
  .mobile-header-menu-parts.active .mobile-header-menu-parts__part-1 {
    top: 0;
    left: 0;
  }
  .mobile-header-menu-parts.active .mobile-header-menu-parts__part-2 {
    bottom: 0;
    left: 0;
  }
  .mobile-header-menu-parts.active .mobile-header-menu-parts__part-3 {
    bottom: 0;
    right: 0;
  }
  .mobile-footer-menu-parts {
    position: absolute;
  }
  .mobile-footer-menu-parts__part {
    position: fixed;
    transition: all 1.5s ease;
  }
  .mobile-footer-menu-parts__part-1 {
    top: -235px;
    left: -420px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 235px 420px 0 0;
    border-color: #28e1b9 transparent transparent transparent;
  }
  .mobile-footer-menu-parts__part-2 {
    bottom: -145px;
    right: -255px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 145px 255px;
    border-color: transparent transparent #2ff0c6 transparent;
  }
  .mobile-footer-menu-parts.active .mobile-footer-menu-parts__part-1 {
    top: 0;
    left: 0;
  }
  .mobile-footer-menu-parts.active .mobile-footer-menu-parts__part-2 {
    bottom: 0;
    right: 0;
  }
  footer {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    flex-direction: column;
    justify-content: center;
    background: #2aeac1;
    transition: all 1s ease;
  }
  footer > div {
    position: relative;
    flex-direction: column;
    justify-content: center;
  }
  footer a {
    font-size: 16px;
    line-height: 16px;
    height: 27px;
    border-radius: 14px;
    padding: 4px 25px 0;
    margin: 8px 0;
    text-decoration: none;
  }
  footer a:hover,
  footer a.active {
    background: #66f8d8;
    text-decoration: none;
  }
  footer a.big {
    font-size: 16px;
    line-height: 16px;
    height: 50px;
    border-radius: 25px;
    padding: 16px 35px 0;
    margin: 15px 0 10px;
    background: #fff;
  }
  header {
    position: fixed;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    flex-direction: column;
    justify-content: center;
    transition: all 1s ease;
  }
  header a {
    font-size: 18px;
    line-height: 18px;
    height: 50px;
    border-radius: 25px;
    padding: 15px 18px 0;
    margin: 25px 0;
  }
  .cursor {
    display: none;
  }
  .link {
    cursor: pointer;
  }
  .content {
    width: 288px;
  }
  .triangle {
    border: none;
    transition: all 0.3s ease;
  }
  .triangle.hidden {
    top: -60px;
  }
  .triangle .logo {
    width: 116px;
    height: 18px;
    transform: none;
    bottom: auto;
    left: 16px;
    top: 14px;
  }
  .hamburger-header {
    top: 16px;
    right: 16px;
    width: 22px;
  }
  .hamburger-header span {
    height: 2px;
  }
  .hamburger-header span:last-child {
    margin-top: 8px;
  }
  .hamburger-footer {
    position: relative;
    width: 18px;
  }
  .hamburger-footer span {
    width: 18px;
    display: block;
    background: #000;
    height: 2px;
  }
  .hamburger-footer span:last-child {
    width: 13px;
    margin-top: 8px;
  }
  .hamburger-close {
    display: block;
    position: absolute;
    line-height: 0;
    z-index: 999;
    top: 16px;
    right: 16px;
    width: 22px;
    margin-top: 6px;
  }
  .hamburger-close span {
    transform: rotate(45deg);
    height: 2px;
    display: block;
    background: #000;
  }
  .hamburger-close span:last-child {
    transform: rotate(-45deg);
    margin-top: -2px;
  }
  .footer-mobile {
    background: #e8e8e8;
    height: 48px;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    font-size: 11.5px;
    font-family: 'EuclidCircularA-Medium', sans-serif;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    transition: transform 1s ease;
  }
  .footer-mobile.hidden {
    transform: translateY(100%);
  }
  .voile img {
    width: 50px;
    height: 50px;
  }
  h1,
  .h1 {
    font-size: 27px;
    line-height: 30px;
  }
  .page {
    overflow-y: scroll;
  }
  .page-Map {
    overflow-y: hidden;
  }
  .page-About:before,
  .page-Team:before,
  .page-Contacts:before,
  .page-Press:before,
  .page-Article:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #d6dade;
    height: 44px;
    z-index: 11;
  }
  .page-Home {
    padding-bottom: 48px;
    background: none!important;
  }
  .page-Home .content {
    padding-bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .page-Home h1,
  .page-Home .h1 {
    text-align: center;
    margin-bottom: 25px;
  }
  .page-Home .download {
    right: auto;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -50vw;
  }
  .page-Home .btn {
    border-radius: 0;
    width: 100vw;
    height: 58px;
    font-size: 18px;
    line-height: 18px;
    padding: 18px 0 0;
  }
  .page-How .page {
    overflow: hidden;
  }
  .page-How .navigation {
    bottom: 0;
  }
  .page-How .download {
    right: auto;
    position: absolute;
    left: 16px;
    bottom: 58px;
    margin-left: 0;
  }
  .page-How .btn {
    font-size: 19px;
    line-height: 19px;
    right: auto;
    left: 16px;
    bottom: 60px;
    height: 44px;
    padding: 12px 20px 0;
    border-radius: 22px;
  }
  .page-How .text br {
    display: none;
  }
  .page-Team .half__pic {
    background-position: center top -10px;
    background-size: 190%;
  }
  .page-Rules:before {
    content: '';
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    height: 60px;
    background: white;
    z-index: 1;
  }
  .page-Rules .half-press .half__text {
    padding-top: 0;
  }
  .mobileHomeBg {
    width: 100vw;
    height: 70vw;
    background: no-repeat center / 150% auto;
    margin-bottom: -20px;
  }
  .app-screen {
    right: -17%;
    width: 135%;
    height: 135%;
    bottom: -15%;
  }
  .navigation {
    bottom: 48px;
    width: 100%;
    justify-content: flex-end;
    border-radius: 0;
    position: fixed;
    right: 0;
  }
  .prev,
  .next {
    width: 70px;
    height: 48px;
    border-radius: 0;
    background-size: 11px auto;
  }
  .pages {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    font-size: 19px;
    font-family: 'EuclidCircularA-Medium', sans-serif;
    display: block;
  }
  .steps-page {
    width: 100%;
    margin-left: 0;
    padding: 40px 16px 48px;
    justify-content: flex-start;
  }
  .steps-page .text {
    width: 650px;
  }
  .steps-page .text span {
    display: block;
  }
  .title {
    font-size: 40px;
    margin-bottom: 13px;
  }
  .text {
    font-size: 12px;
  }
  p + p {
    margin-top: 10px;
  }
  .number {
    font-size: 605px;
    transform: translateY(-50%) translateX(-25%);
  }
  .half {
    display: block;
    height: auto;
  }
  .half__text {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 110px;
    padding-top: 15px;
  }
  .half__text-absolute {
    height: auto;
    position: relative;
    padding-bottom: 0!important;
  }
  .half__text-absolute > div {
    position: relative;
    height: auto;
    padding-bottom: 0;
    padding-left: 0;
  }
  .half__pic {
    position: relative;
    top: 0;
    height: 185px;
    margin-top: 44px;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
  }
  .half-reverse .half__text {
    margin-left: 0;
    padding-left: 0;
  }
  .half-reverse .half__pic {
    left: 50%;
  }
  .half-about .half__text {
    font-size: 12.5px;
  }
  .half-about .half__text-small {
    font-size: 11.5px;
  }
  .half-about .half__text br {
    display: none;
  }
  .half-about .scroll-area,
  .half-about .magic-area {
    max-height: none;
  }
  .half-members .scroll-area {
    max-height: none;
  }
  .half-members .half__text {
    font-size: 12.5px;
    padding-bottom: 110px;
  }
  .half-members .half__pic {
    width: 250px;
  }
  .half-members .half__pic-next {
    width: 70px;
    position: absolute;
    top: 0;
    margin-left: 0;
    left: 250px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .half-members .half__pic-next .half__pic {
    left: 0;
    margin-left: 0;
  }
  .half-members .half__pic-next.inAction {
    opacity: 0;
  }
  .half-contacts .scroll-area {
    max-height: none;
  }
  .half-contacts .half__text {
    font-size: 13.5px;
    padding-bottom: 110px;
    padding-top: 245px;
  }
  .half-contacts .half__pic {
    margin-top: 96px;
    position: absolute;
    height: 135px;
  }
  .half-press .scroll-area {
    max-height: none;
  }
  .half-press .half__text {
    padding-top: 44px;
    height: auto;
    padding-bottom: 110px;
    margin-bottom: 0;
  }
  .half-article .scroll-area {
    max-height: none;
  }
  .half-article .half__text {
    padding-bottom: 110px;
  }
  .members {
    display: none;
  }
  .member__name {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .vertical-tabs__content {
    height: auto;
  }
  .vertical-tab__content {
    position: absolute;
    left: -16px;
    top: 0;
    width: 100vw;
    padding: 245px 16px 110px;
  }
  .contacts-block {
    position: fixed;
    bottom: 48px;
    left: 0;
    width: 100%;
    margin-top: 0;
  }
  .contacts-block__title {
    padding: 0 16px;
  }
  .contacts-block .btn {
    border-radius: 0;
    margin: 0;
    height: 48px;
    width: 100%;
    font-size: 18px;
    line-height: 18px;
    padding: 14px 0 0;
  }
  .contacts-block .btn-midi {
    font-size: 11px;
    line-height: 11px;
    width: 50%;
    padding: 18px 0 0;
    background: #4ef2ce;
  }
  .contacts-block .btn i {
    display: none;
  }
  .contacts-block .btn + .btn {
    margin-left: 0;
    background: #28e1b9;
  }
  .contacts-block__text-small {
    font-size: 11.5px;
    line-height: 17px;
  }
  .contacts-block__btns {
    display: flex;
    left: 0;
    width: 100vw;
    position: relative;
  }
  .contacts-block__btns-2 {
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 0;
  }
  .contacts-block__btns-2 .btn-fixed {
    font-size: 13px;
    line-height: 13px;
    width: 50%;
    padding: 18px 0 0;
  }
  .contacts-block__btns-2 .btn:nth-child(1) {
    background: #4ef2ce;
  }
  .contacts-block__btns-2 .btn:nth-child(3) {
    background: #63f9d8;
  }
  .contacts-block__btns-2 .btn:nth-child(4) {
    background: #5beece;
  }
  .articles {
    margin-left: -16px;
    width: 100vw;
  }
  .article {
    font-size: 11.5px;
  }
  .article__link {
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 0;
    margin-top: 25px;
    padding: 1px 16px 5px;
  }
  .article__title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .back {
    margin-left: 0;
    font-size: 18px;
    top: auto;
    bottom: 48px;
    position: fixed;
    width: 100%;
    font-family: 'EuclidCircularA-Medium', sans-serif;
    padding: 10px 0 0 0;
    background: #28e1b9;
    left: 0;
    text-align: center;
    height: 48px;
    z-index: 11;
  }
  .select-city {
    position: absolute;
    left: 16px;
    top: 37px;
    z-index: 11;
    font-size: 12px;
    font-family: 'EuclidCircularA-Medium', sans-serif;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.3s ease;
  }
  .select-city::-ms-expand {
    display: none;
  }
  .select-city.hidden {
    top: -23px;
  }
  .select-filter {
    border-radius: 15px;
    border: none;
    font-size: 24px;
    font-family: 'EuclidCircularA-Semibold', sans-serif;
    padding: 7px 15px 9px;
    width: 100%;
    position: relative;
    background: #f9f9f9 url(./assets/images/arrow-down.svg) no-repeat right 15px center;
    background-size: 13px auto;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .select-filter::-ms-expand {
    display: none;
  }
  .select-filter.small {
    font-size: 20px;
    font-family: 'EuclidCircularA-Medium', sans-serif;
  }
  .filter {
    top: auto;
    bottom: 0;
    right: -3vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
    padding: 27px 26px 25px;
    border-radius: 50px 50px 0 0;
    width: 106vw;
  }
  .filter-cars {
    bottom: -380px;
  }
  .filter-cars.open {
    bottom: -225px;
  }
  .filter-cars.open.full {
    bottom: -25px;
  }
  .filter-cars.open.full .filter-tab-cars {
    overflow-y: scroll;
  }
  .filter-cars .filter-tab-cars {
    height: 380px;
    padding-top: 25px!important;
    padding-right: 16px;
    padding-left: 16px;
    margin: 0 -16px;
    overflow-y: hidden;
  }
  .filter-cars.moving {
    transition: all 0.3s ease;
  }
  .filter-tab + .filter-tab {
    padding-top: 18px;
  }
  .filter__drag {
    margin: -27px auto 0;
    text-align: center;
    line-height: 0;
    padding: 13px 0 18px;
  }
  .filter__drag span {
    width: 55px;
    height: 4px;
    border-radius: 2px;
    background: #d6dade;
    display: inline-block;
  }
  .btn-small {
    font-size: 13px;
    line-height: 13px;
    border-radius: 14px;
    padding: 6px 17px 7px;
  }
  .fancybox-slide {
    padding: 0;
    -webkit-overflow-scrolling: auto;
  }
  .fancybox-close {
    width: 18px;
    height: 18px;
    top: 14px;
    right: 16px;
  }
  .fancybox-content {
    height: 100%;
    -webkit-overflow-scrolling: auto;
  }
  .fancybox-content .triangle {
    display: none;
  }
  .popup {
    position: absolute;
    border-radius: 0;
    width: 100vw;
    height: 100%;
  }
  .popup__tab {
    padding: 50px 0 0 16px;
    text-align: left;
    height: 80px;
    font-size: 18.5px;
  }
  .popup__tab__content {
    font-size: 14px;
    padding: 0;
  }
  .popup__tab__content .popup__content-scroll {
    height: calc(100vh - 80px);
  }
  .popup-ok .popup__content-scroll {
    height: calc(100vh - 50px);
  }
  .popup__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: #28e1b9;
    text-align: center;
    font-size: 18px;
    font-family: 'EuclidCircularA-Medium', sans-serif;
    padding: 11px 0 0 0;
  }
  .popup__text-big {
    font-size: 17px;
  }
  .popup__content {
    padding: 20px 16px;
  }
  .popup__content-scroll {
    overflow-y: scroll;
    height: 100vh;
  }
  .popup__content-blocks {
    background: #d6dade;
    padding: 0!important;
  }
  .popup__content-blocks .block:first-child {
    padding-top: 30px;
  }
  .popup__title {
    font-size: 18px;
  }
  .popup__btn {
    display: block;
  }
  .popup__number {
    font-size: 411px;
    line-height: 350px;
    right: 50%;
    transform: translateX(50%);
  }
  .popup__next,
  .popup__prev {
    height: 60px;
    font-size: 18px;
    padding: 18px 33px;
    z-index: 10;
  }
  .popup__next:before,
  .popup__prev:before {
    display: none;
  }
  .popup__next:after,
  .popup__prev:after {
    width: 15px;
    height: 26px;
    bottom: 17px;
  }
  .popup__next:before {
    border-radius: 0 0 60px 0;
  }
  .popup__next:after {
    right: 16px;
  }
  .popup__prev:before {
    border-radius: 0 0 0 60px;
  }
  .popup__prev:after {
    left: 16px;
  }
  .block {
    font-size: 12px;
    padding: 16px;
  }
  .block__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .block-white .block__title {
    font-size: 23px;
  }
  .owl-carousel-popup .owl-item {
    height: calc(100vh - 140px);
  }
  .owl-carousel-popup .owl-stage-outer {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }
  .car-names {
    margin: 0;
    height: auto;
  }
  .car-name {
    font-size: 14px;
    margin-right: 0;
    margin-bottom: 0;
  }
  .car-name__name {
    padding: 6px 15px 7px;
    border-radius: 21px;
  }
  .car-name__cnt {
    font-size: 11px;
    padding-right: 16px;
  }
  .cars {
    height: 70px;
  }
  .cars:before,
  .cars:after {
    width: 52px;
  }
  .cars:after {
    left: 52px;
    border-width: 93px 0 0 203px;
  }
  .cars-block {
    padding-top: 80px;
    margin: 0 -16px;
    height: 150px;
  }
  .car {
    height: 140px;
  }
  .carousel-block {
    height: 175px;
    border-radius: 0;
    margin: 0 -16px;
    padding: 16px 0 0;
    max-width: none;
  }
  .tariff {
    margin: 0 0 0 16px;
    border-radius: 22px;
    padding: 18px 10px 10px;
    font-size: 12px;
    height: 145px;
  }
  .tariff__content {
    padding: 0 13px;
  }
  .tariff__price {
    font-size: 15.5px;
    margin-bottom: 10px;
  }
  .tariff__wait {
    margin-bottom: 5px;
  }
  .tariff .btn {
    font-size: 13.5px;
    padding: 14px 17px 15px;
    border-radius: 21px;
  }
  .tariff-data {
    margin-bottom: -15px;
  }
  .tariff-data__row {
    margin-bottom: 15px;
  }
  .ps.ps--active-y {
    margin-right: -20px;
    padding-right: 20px;
  }
  body {
    font-size: 5vw;
  }
  #app.openHeader .hamburger-header {
    margin-top: 1.875vw;
  }
  #app.openHeader .hamburger-header span:last-child {
    margin-top: -0.625vw;
  }
  #app.openFooter .hamburger-header {
    top: -15.625vw;
  }
  .mobile-header-menu-parts__part-1 {
    top: -68.75vw;
    left: -125vw;
    border-width: 68.75vw 125vw 0 0;
  }
  .mobile-header-menu-parts__part-2 {
    bottom: -23.4375vw;
    left: -48.4375vw;
    border-width: 23.4375vw 0 0 48.4375vw;
  }
  .mobile-header-menu-parts__part-3 {
    bottom: -39.0625vw;
    right: -65.625vw;
    border-width: 0 0 39.0625vw 65.625vw;
  }
  .mobile-footer-menu-parts__part-1 {
    top: -73.4375vw;
    left: -131.25vw;
    border-width: 73.4375vw 131.25vw 0 0;
  }
  .mobile-footer-menu-parts__part-2 {
    bottom: -45.3125vw;
    right: -79.6875vw;
    border-width: 0 0 45.3125vw 79.6875vw;
  }
  footer a {
    font-size: 5vw;
    line-height: 5vw;
    height: 8.4375vw;
    border-radius: 4.375vw;
    padding: 1.25vw 7.8125vw 0;
    margin: 2.5vw 0;
  }
  footer a.big {
    font-size: 5vw;
    line-height: 5vw;
    height: 15.625vw;
    border-radius: 7.8125vw;
    padding: 5vw 10.9375vw 0;
    margin: 4.6875vw 0 3.125vw;
  }
  header a {
    font-size: 5.625vw;
    line-height: 5.625vw;
    height: 15.625vw;
    border-radius: 7.8125vw;
    padding: 4.6875vw 5.625vw 0;
    margin: 4.6875vw 0;
  }
  header a:last-child {
    position: absolute;
    bottom: 1px;
  }
  .content {
    width: 90vw;
  }
  .triangle.hidden {
    top: -18.75vw;
  }
  .triangle .logo {
    width: 36.25vw;
    height: 5.625vw;
    left: 5vw;
    top: 4.375vw;
  }
  .ps__container {
    padding-right: 3.125vw;
  }
  .hamburger-header {
    top: 5vw;
    right: 5vw;
    width: 6.875vw;
  }
  .hamburger-header span:last-child {
    margin-top: 2.5vw;
  }
  .hamburger-footer {
    width: 5.625vw;
  }
  .hamburger-footer span {
    width: 5.625vw;
    height: 0.625vw;
  }
  .hamburger-footer span:last-child {
    width: 4.0625vw;
    margin-top: 2.5vw;
  }
  .hamburger-close {
    top: 5vw;
    right: 5vw;
    width: 6.875vw;
    margin-top: 1.875vw;
  }
  .hamburger-close span {
    height: 0.625vw;
  }
  .hamburger-close span:last-child {
    margin-top: -0.625vw;
  }
  .footer-mobile {
    min-height: 15vw;
    height: auto;
    font-size: 3.59375vw;
    padding: 0 5vw;
    padding-top: 10px;
  }
  .voile img {
    width: 15.625vw;
    height: 15.625vw;
  }
  h1,
  .h1 {
    font-size: 7.5vw;
    line-height: 8.4375vw;
  }
  .page-About:before,
  .page-Team:before,
  .page-Contacts:before,
  .page-Press:before,
  .page-Article:before {
    height: 13.75vw;
  }
  .page-Home {
    padding-bottom: 15vw;
  }
  .page-Home h1,
  .page-Home .h1 {
    margin-bottom: 7.8125vw;
  }
  .page-Home .btn {
    height: 18.125vw;
    font-size: 5.625vw;
    line-height: 5.625vw;
    padding: 5.625vw 0 0;
  }
  .page-How .download {
    left: 5vw;
    bottom: 18.125vw;
  }
  .page-How .btn {
    font-size: 5.9375vw;
    line-height: 5.9375vw;
    left: 5vw;
    bottom: 18.75vw;
    height: 13.75vw;
    padding: 3.75vw 6.25vw 0;
    border-radius: 6.875vw;
  }
  .mobileHomeBg {
    margin-bottom: -6.25vw;
  }
  .navigation {
    bottom: 15vw;
  }
  .prev,
  .next {
    width: 21.875vw;
    height: 15vw;
    background-size: 3.4375vw auto;
  }
  .pages {
    left: 5vw;
    font-size: 5.9375vw;
  }
  .steps-page {
    padding: 12.5vw 5vw 15vw;
  }
  .steps-page .text {
    width: 203.125vw;
  }
  .title {
    font-size: 12.5vw;
    margin-bottom: 4.0625vw;
  }
  .text {
    font-size: 3.75vw;
  }
  p + p {
    margin-top: 3.125vw;
  }
  .number {
    font-size: 189.0625vw;
  }
  .half__text {
    padding-bottom: 34.375vw;
    padding-top: 4.6875vw;
  }
  .half__pic {
    height: 57.8125vw;
    margin-top: 13.75vw;
  }
  .half-about .half__text {
    font-size: 3.90625vw;
  }
  .half-about .half__text-small {
    font-size: 3.59375vw;
  }
  .half-members .half__text {
    font-size: 3.90625vw;
    padding-bottom: 34.375vw;
  }
  .half-members .half__pic {
    width: 78.125vw;
  }
  .half-members .half__pic-next {
    width: 21.875vw;
    left: 78.125vw;
  }
  .half-contacts .half__text {
    font-size: 4.21875vw;
    padding-bottom: 34.375vw;
    padding-top: 76.5625vw;
  }
  .half-contacts .half__pic {
    margin-top: 30vw;
    height: 42.1875vw;
  }
  .half-press .half__text {
    padding-top: 13.75vw;
    padding-bottom: 34.375vw;
  }
  .half-article .half__text {
    padding-bottom: 34.375vw;
  }
  .member__name {
    font-size: 4.0625vw;
    margin-bottom: 4.6875vw;
  }
  .vertical-tab__content {
    left: -5vw;
    padding: 76.5625vw 5vw 34.375vw;
  }
  .contacts-block {
    bottom: 15vw;
  }
  .contacts-block__title {
    padding: 0 5vw;
  }
  .contacts-block .btn {
    height: 15vw;
    font-size: 5.625vw;
    line-height: 5.625vw;
    padding: 4.375vw 0 0;
  }
  .contacts-block .btn-midi {
    font-size: 3.4375vw;
    line-height: 3.4375vw;
    padding: 5.625vw 0 0;
  }
  .contacts-block__text-small {
    font-size: 3.59375vw;
    line-height: 5.3125vw;
  }
  .contacts-block__btns-2 .btn-fixed {
    font-size: 4.0625vw;
    line-height: 4.0625vw;
    padding: 5.625vw 0 0;
  }
  .articles {
    margin-left: -5vw;
  }
  .article {
    font-size: 3.59375vw;
  }
  .article__link {
    font-size: 8.125vw;
    line-height: 8.75vw;
    margin-top: 7.8125vw;
    padding: 0.3125vw 5vw 1.5625vw;
  }
  .article__title {
    font-size: 6.25vw;
    margin-bottom: 6.25vw;
  }
  .back {
    font-size: 5.625vw;
    bottom: 15vw;
    padding: 3.125vw 0 0 0;
    height: 15vw;
  }
  .select-city {
    left: 5vw;
    top: 11.5625vw;
    z-index: 11;
    font-size: 3.75vw;
  }
  .select-city.hidden {
    top: -7.1875vw;
  }
  .select-filter {
    border-radius: 4.6875vw;
    font-size: 5.625vw;
    background-position: right 4.6875vw center;
  }
  .select-filter.small {
    font-size: 6.25vw;
  }
  .filter {
    position: fixed;
    box-shadow: 0 0 6.25vw rgba(0, 0, 0, 0.04);
    padding: 8.4375vw 0 0;
    border-radius: 15.625vw 15.625vw 0 0;
    transition: bottom 0.3s ease;
  }
  .filter-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 2.1875vw 5.625vw 2.8125vw;
  }
  .filter-cars {
    height: 167.1875vw;
    bottom: -139.0625vw;
  }
  .filter-cars.open {
    bottom: -65.625vw;
  }
  .filter-cars.open.full {
    bottom: -9.375vw;
  }
  .filter-cars .filter-tab-cars {
    height: 118.75vw;
    padding-top: 7.8125vw !important;
    padding-right: 5vw;
    padding-left: 5vw;
    margin: 0 -5vw;
  }
  .filter-tab + .filter-tab {
    padding-top: 5.625vw;
  }
  .filter__drag {
    margin: -8.4375vw auto 0;
    padding: 4.0625vw 0 5.625vw;
  }
  .filter__drag span {
    width: 17.1875vw;
    height: 1.25vw;
    border-radius: 0.625vw;
  }
  .btn-small {
    font-size: 4.0625vw;
    line-height: 4.0625vw;
    border-radius: 4.375vw;
    padding: 1.875vw 5.3125vw 2.1875vw;
  }
  .fancybox-close {
    width: 5.625vw;
    height: 5.625vw;
    top: 4.375vw;
    right: 5vw;
  }
  .popup__tab {
    padding: 15.625vw 0 0 5vw;
    height: 25vw;
    font-size: 5.78125vw;
  }
  .popup__tab__content {
    font-size: 4.375vw;
  }
  .popup__btn {
    height: 15.625vw;
    font-size: 5.625vw;
    padding: 3.4375vw 0 0 0;
  }
  .popup__text-big {
    font-size: 5.3125vw;
  }
  .popup__content {
    padding: 6.25vw 5vw;
  }
  .popup__content-blocks .block:first-child {
    padding-top: 9.375vw;
  }
  .popup__title {
    font-size: 5.625vw;
  }
  .popup__number {
    font-size: 128.4375vw;
    line-height: 109.375vw;
  }
  .popup__next,
  .popup__prev {
    height: 18.75vw;
    font-size: 5.625vw;
    padding: 5.625vw 10.3125vw;
  }
  .popup__next:after,
  .popup__prev:after {
    width: 4.6875vw;
    height: 8.125vw;
    bottom: 5.3125vw;
  }
  .popup__next:before {
    border-radius: 0 0 18.75vw 0;
  }
  .popup__next:after {
    right: 5vw;
  }
  .popup__prev:before {
    border-radius: 0 0 0 18.75vw;
  }
  .popup__prev:after {
    left: 5vw;
  }
  .block {
    font-size: 3.75vw;
    padding: 5vw;
  }
  .block__title {
    font-size: 5.625vw;
    margin-bottom: 6.25vw;
  }
  .block-white .block__title {
    font-size: 7.1875vw;
  }
  .car-name {
    font-size: 4.375vw;
  }
  .car-name__name {
    padding: 1.875vw 4.6875vw 2.1875vw;
    border-radius: 6.5625vw;
  }
  .car-name__cnt {
    font-size: 3.4375vw;
    padding-right: 5vw;
  }
  .cars {
    height: 21.875vw;
  }
  .cars:before,
  .cars:after {
    width: 16.25vw;
  }
  .cars:after {
    left: 16.25vw;
    border-width: 29.0625vw 0 0 63.4375vw;
  }
  .cars-block {
    padding-top: 25vw;
    margin: 0 -5vw;
    height: 46.875vw;
  }
  .car {
    height: 43.75vw;
  }
  .carousel-block {
    height: 54.6875vw;
    margin: 0 -5vw;
    padding: 5vw 0 0;
  }
  .tariff {
    margin: 0 0 0 5vw;
    border-radius: 6.875vw;
    padding: 5.625vw 3.125vw 3.125vw;
    font-size: 3.75vw;
    height: 45.3125vw;
  }
  .tariff__content {
    padding: 0 4.0625vw;
  }
  .tariff__price {
    font-size: 4.84375vw;
    margin-bottom: 3.125vw;
  }
  .tariff__wait {
    margin-bottom: 1.5625vw;
  }
  .tariff .btn {
    font-size: 4.21875vw;
    padding: 4.375vw 5.3125vw 4.6875vw;
    border-radius: 6.5625vw;
  }
  .tariff-data {
    margin-bottom: -4.6875vw;
  }
  .tariff-data__row {
    margin-bottom: 4.6875vw;
  }
  .filter-cars .filter-tab-cars {
    height: 100%;
  }
  .ps.ps--active-y {
    margin-right: -6.25vw;
    padding-right: 6.25vw;
  }
}
@media screen and (min-aspect-ratio: 40/60) and (max-width: 767px) {
  .filter {
    height: 100%;
    top: 55%;
    transition: top 1s ease;
  }
  .filter-cars {
    top: 80%;
    padding-bottom: 0;
  }
  .filter-cars.open {
    top: 55%;
  }
  .filter-cars.open.full {
    top: 0;
  }
  .filter-cars.open.full .filter-wrapper {
    overflow-y: scroll;
  }
}
@media only screen and (max-height: 439px) and (max-width: 767px) {
  .turn {
    display: flex;
    position: fixed;
    z-index: 99999999999;
    background: #28e1b9;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: bold;
  }
}
path.leaflet-interactive {
  animation: fadeIn;
  -webkit-animation: fadeIn;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
}
