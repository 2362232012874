
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        







































































































































.first-block {
  padding-top: 0 !important;
}
