



































.urgent-service-fallback {
  width: 375px;
  height: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(38, 38, 38, 0.12);
  border-radius: 24px;
  &_mobile {
    height: 206px;
  }
  .fallback-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1f2021;
  }
  .fallback-link {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: #1f2021;
    border-radius: 20px;
    color: #28e1b9;
    text-decoration: none;
  }
}
