































































































































































































































































































































































































































.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  .map {
    width: 100%;
    height: 100%;
  }
  .geo-icon {
    transform: translateY(-50%) translateX(-50%);
  }
}
