.sizesMobile(@factor) {
  body {
    font-size: 16 / 320 * 100vw * @factor;
  }
  #app {
    &.openHeader {
      .hamburger {
        &-header {
          margin-top: 6 / 320 * 100vw * @factor;

          span {
            &:last-child {
              margin-top: -2 / 320 * 100vw * @factor;
            }
          }
        }
      }
    }

    &.openFooter {
      .hamburger {
        &-header {
          top: -50 / 320 * 100vw * @factor;
        }
      }
    }
  }
  .mobile-header-menu-parts {
    &__part {
      &-1 {
        top: -220 / 320 * 100vw * @factor;
        left: -400 / 320 * 100vw * @factor;
        border-width: 220 / 320 * 100vw * @factor 400 / 320 * 100vw * @factor 0 0;
      }

      &-2 {
        bottom: -75 / 320 * 100vw * @factor;
        left: -155 / 320 * 100vw * @factor;
        border-width: 75 / 320 * 100vw * @factor 0 0 155 / 320 * 100vw * @factor;
      }

      &-3 {
        bottom: -125 / 320 * 100vw * @factor;
        right: -210 / 320 * 100vw * @factor;
        border-width: 0 0 125 / 320 * 100vw * @factor 210 / 320 * 100vw * @factor;
      }
    }
  }
  .mobile-footer-menu-parts {
    &__part {
      &-1 {
        top: -235 / 320 * 100vw * @factor;
        left: -420 / 320 * 100vw * @factor;
        border-width: 235 / 320 * 100vw * @factor 420 / 320 * 100vw * @factor 0 0;
      }

      &-2 {
        bottom: -145 / 320 * 100vw * @factor;
        right: -255 / 320 * 100vw * @factor;
        border-width: 0 0 145 / 320 * 100vw * @factor 255 / 320 * 100vw * @factor;
      }
    }
  }
  footer {
    a {
      font-size: 16 / 320 * 100vw * @factor;
      line-height: 16 / 320 * 100vw * @factor;
      height: 27 / 320 * 100vw * @factor;
      border-radius: 14 / 320 * 100vw * @factor;
      padding: 4 / 320 * 100vw * @factor 25 / 320 * 100vw * @factor 0;
      margin: 8 / 320 * 100vw * @factor 0;

      &.big {
        font-size: 16 / 320 * 100vw * @factor;
        line-height: 16 / 320 * 100vw * @factor;
        height: 50 / 320 * 100vw * @factor;
        border-radius: 25 / 320 * 100vw * @factor;
        padding: 16 / 320 * 100vw * @factor 35 / 320 * 100vw * @factor 0;
        margin: 15 / 320 * 100vw * @factor 0 10 / 320 * 100vw * @factor;
      }
    }
  }
  header {
    a {
      font-size: 18 / 320 * 100vw * @factor;
      line-height: 18 / 320 * 100vw * @factor;
      height: 50 / 320 * 100vw * @factor;
      border-radius: 25 / 320 * 100vw * @factor;
      padding: 15 / 320 * 100vw * @factor 18 / 320 * 100vw * @factor 0;
      margin: 15 / 320 * 100vw * @factor 0;
    }

    a:last-child {
      position: absolute;
      bottom: 1px;
    }
  }
  .content {
    width: 288 / 320 * 100vw * @factor;
  }
  .triangle {
    &.hidden {
      top: -60 / 320 * 100vw * @factor;
    }

    .logo {
      width: 116 / 320 * 100vw * @factor;
      height: 18 / 320 * 100vw * @factor;
      left: 16 / 320 * 100vw * @factor;
      top: 14 / 320 * 100vw * @factor;
    }
  }
  .ps {
    &__container {
      padding-right: 10 / 320 * 100vw * @factor;
    }
  }
  .hamburger {
    &-header {
      top: 16 / 320 * 100vw * @factor;
      right: 16 / 320 * 100vw * @factor;
      width: 22 / 320 * 100vw * @factor;

      span {
        //height: 2 / 320 * 100vw * @factor;

        &:last-child {
          margin-top: 8 / 320 * 100vw * @factor;
        }
      }
    }

    &-footer {
      width: 18 / 320 * 100vw * @factor;

      span {
        width: 18 / 320 * 100vw * @factor;
        height: 2 / 320 * 100vw * @factor;

        &:last-child {
          width: 13 / 320 * 100vw * @factor;
          margin-top: 8 / 320 * 100vw * @factor;
        }
      }
    }

    &-close {
      top: 16 / 320 * 100vw * @factor;
      right: 16 / 320 * 100vw * @factor;
      width: 22 / 320 * 100vw * @factor;
      margin-top: 6 / 320 * 100vw * @factor;

      span {
        height: 2 / 320 * 100vw * @factor;

        &:last-child {
          margin-top: -2 / 320 * 100vw * @factor;
        }
      }
    }
  }
  .footer {
    &-mobile {
      min-height: 48 / 320 * 100vw * @factor;
      height: auto;
      font-size: 11.5 / 320 * 100vw * @factor;
      padding: 0 16 / 320 * 100vw * @factor;
      padding-top: 10px;
    }
  }
  .voile {
    img {
      width: 50 / 320 * 100vw * @factor;
      height: 50 / 320 * 100vw * @factor;
    }
  }
  h1, .h1 {
    font-size: 24 / 320 * 100vw * @factor;
    line-height: 27 / 320 * 100vw * @factor;
  }
  .page {
    &-About, &-Team, &-Contacts, &-Press, &-Article {
      &:before {
        height: 44 / 320 * 100vw * @factor;
      }
    }

    &-Home {
      padding-bottom: 48 / 320 * 100vw * @factor;

      h1, .h1 {
        margin-bottom: 25 / 320 * 100vw * @factor;
      }

      .btn {
        height: 58 / 320 * 100vw * @factor;
        font-size: 18 / 320 * 100vw * @factor;
        line-height: 18 / 320 * 100vw * @factor;
        padding: 18 / 320 * 100vw * @factor 0 0;
      }
    }

    &-How {
      .download {
        left: 16 / 320 * 100vw * @factor;
        bottom: 58 / 320 * 100vw * @factor;
      }

      .btn {
        font-size: 19 / 320 * 100vw * @factor;
        line-height: 19 / 320 * 100vw * @factor;
        left: 16 / 320 * 100vw * @factor;
        bottom: 60 / 320 * 100vw * @factor;
        height: 44 / 320 * 100vw * @factor;
        padding: 12 / 320 * 100vw * @factor 20 / 320 * 100vw * @factor 0;
        border-radius: 22 / 320 * 100vw * @factor;
      }
    }
  }
  .mobileHomeBg{
    margin-bottom: -20 / 320 * 100vw * @factor;
  }
  .navigation {
    bottom: 48 / 320 * 100vw * @factor;
  }
  .prev, .next {
    width: 70 / 320 * 100vw * @factor;
    height: 48 / 320 * 100vw * @factor;
    background-size: 11 / 320 * 100vw * @factor auto;
  }
  .pages {
    left: 16 / 320 * 100vw * @factor;
    font-size: 19 / 320 * 100vw * @factor;
  }
  .steps-page {
    padding: 40 / 320 * 100vw * @factor 16 / 320 * 100vw * @factor 48 / 320 * 100vw * @factor;

    .text {
      width: 650 / 320 * 100vw * @factor;
    }
  }
  .title {
    font-size: 40 / 320 * 100vw * @factor;
    margin-bottom: 13 / 320 * 100vw * @factor;
  }
  .text {
    font-size: 12 / 320 * 100vw * @factor;
  }
  p {
    + p {
      margin-top: 10 / 320 * 100vw * @factor;
    }
  }
  .number {
    font-size: 605 / 320 * 100vw * @factor;
  }
  .half {
    &__text {
      padding-bottom: 110 / 320 * 100vw * @factor;
      padding-top: 15 / 320 * 100vw * @factor;
    }

    &__pic {
      height: 185 / 320 * 100vw * @factor;
      margin-top: 44 / 320 * 100vw * @factor;
    }

    &-about {
      .half {
        &__text {
          font-size: 12.5 / 320 * 100vw * @factor;

          &-small {
            font-size: 11.5 / 320 * 100vw * @factor;
          }
        }
      }
    }

    &-members {
      .half {
        &__text {
          font-size: 12.5 / 320 * 100vw * @factor;
          padding-bottom: 110 / 320 * 100vw * @factor;
        }

        &__pic {
          width: 250 / 320 * 100vw * @factor;

          &-next {
            width: 70 / 320 * 100vw * @factor;
            left: 250 / 320 * 100vw * @factor;
          }
        }
      }
    }

    &-contacts {
      .half {
        &__text {
          font-size: 13.5 / 320 * 100vw * @factor;
          padding-bottom: 110 / 320 * 100vw * @factor;
          padding-top: 245 / 320 * 100vw * @factor;
        }

        &__pic {
          margin-top: 96 / 320 * 100vw * @factor;
          height: 135 / 320 * 100vw * @factor;
        }
      }
    }

    &-press {
      .half {
        &__text {
          padding-top: 44 / 320 * 100vw * @factor;
          padding-bottom: 110 / 320 * 100vw * @factor;
        }
      }
    }

    &-article {
      .half {
        &__text {
          padding-bottom: 110 / 320 * 100vw * @factor;
        }
      }
    }
  }
  .member {
    &__name {
      font-size: 13 / 320 * 100vw * @factor;
      margin-bottom: 15 / 320 * 100vw * @factor;
    }
  }
  .vertical-tab {
    &__content {
      left: -16 / 320 * 100vw * @factor;
      padding: 245 / 320 * 100vw * @factor 16 / 320 * 100vw * @factor 110 / 320 * 100vw * @factor;
    }
  }
  .contacts-block {
    bottom: 48 / 320 * 100vw * @factor;

    &__title {
      padding: 0 16 / 320 * 100vw * @factor;
    }

    .btn {
      height: 48 / 320 * 100vw * @factor;
      font-size: 18 / 320 * 100vw * @factor;
      line-height: 18 / 320 * 100vw * @factor;
      padding: 14 / 320 * 100vw * @factor 0 0;

      &-midi {
        font-size: 11 / 320 * 100vw * @factor;
        line-height: 11 / 320 * 100vw * @factor;
        padding: 18 / 320 * 100vw * @factor 0 0;
      }
    }

    &__text {
      &-small {
        font-size: 11.5 / 320 * 100vw * @factor;
        line-height: 17 / 320 * 100vw * @factor;
      }
    }

    &__btns {
      &-2 {
        .btn {
          &-fixed {
            font-size: 13 / 320 * 100vw * @factor;
            line-height: 13 / 320 * 100vw * @factor;
            padding: 18 / 320 * 100vw * @factor 0 0;
          }
        }
      }
    }
  }
  .articles {
    margin-left: -16 / 320 * 100vw * @factor;
  }
  .article {
    &__link {
      font-size: 26 / 320 * 100vw * @factor;
      line-height: 28 / 320 * 100vw * @factor;
      margin-top: 25 / 320 * 100vw * @factor;
      padding: 1 / 320 * 100vw * @factor 16 / 320 * 100vw * @factor 5 / 320 * 100vw * @factor;
    }

    font-size: 11.5 / 320 * 100vw * @factor;

    &__title {
      font-size: 20 / 320 * 100vw * @factor;
      margin-bottom: 20 / 320 * 100vw * @factor;
    }
  }
  .back {
    font-size: 18 / 320 * 100vw * @factor;
    bottom: 48 / 320 * 100vw * @factor;
    padding: 10 / 320 * 100vw * @factor 0 0 0;
    height: 48 / 320 * 100vw * @factor;
  }
  .select-city {
    left: 16 / 320 * 100vw * @factor;
    top: 37 / 320 * 100vw * @factor;
    z-index: 11;
    font-size: 12 / 320 * 100vw * @factor;

    &.hidden {
      top: -23 / 320 * 100vw * @factor;
    }
  }
  .select-filter {
    border-radius: 15 / 320 * 100vw * @factor;
    font-size: 18 / 320 * 100vw * @factor;;
    background-position: right 15 / 320 * 100vw * @factor center;

    &.small {
      font-size: 20 / 320 * 100vw * @factor;
    }
  }
  .filter {
    position: fixed;
    box-shadow: 0 0 20 / 320 * 100vw * @factor fade(@black, 4%);
    padding: 27 / 320 * 100vw 0 0;
    border-radius: 50 / 320 * 100vw * @factor 50 / 320 * 100vw * @factor 0 0;
    transition: bottom 0.3s ease;

    &-wrapper {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 7 / 320 * 100vw * @factor (15 + 3) / 320 * 100vw * @factor 9 / 320 * 100vw * @factor;
    }

    &-cars {
      height: 535 / 320 * 100vw * @factor;
      bottom: -445 / 320 * 100vw * @factor;

      &.open {
        bottom: -210 / 320 * 100vw * @factor;

        &.full {
          bottom: -30 / 320 * 100vw * @factor;
        }
      }

      .filter-tab-cars {
        height: 380 / 320 * 100vw * @factor;
        padding-top: 25 / 320 * 100vw * @factor !important;
        padding-right: 16 / 320 * 100vw * @factor;
        padding-left: 16 / 320 * 100vw * @factor;
        margin: 0 -16 / 320 * 100vw * @factor;
      }
    }

    &-tab {
      + .filter-tab {
        padding-top: 18 / 320 * 100vw * @factor;
      }
    }

    &__drag {
      margin: -27 / 320 * 100vw * @factor auto 0;
      padding: 13 / 320 * 100vw * @factor 0 18 / 320 * 100vw * @factor;

      span {
        width: 55 / 320 * 100vw * @factor;
        height: 4 / 320 * 100vw * @factor;
        border-radius: 2 / 320 * 100vw * @factor;
      }
    }
  }
  .btn {
    &-small {
      font-size: 13 / 320 * 100vw * @factor;
      line-height: 13 / 320 * 100vw * @factor;
      border-radius: 14 / 320 * 100vw * @factor;
      padding: 6 / 320 * 100vw * @factor 17 / 320 * 100vw * @factor 7 / 320 * 100vw * @factor;
    }
  }
  .fancybox {
    &-close {
      width: 18 / 320 * 100vw * @factor;
      height: 18 / 320 * 100vw * @factor;
      top: 14 / 320 * 100vw * @factor;
      right: 16 / 320 * 100vw * @factor;
    }
  }
  .popup {
    &__tab {
      padding: 50 / 320 * 100vw * @factor 0 0 16 / 320 * 100vw * @factor;
      height: 80 / 320 * 100vw * @factor;
      font-size: 18.5 / 320 * 100vw * @factor;

      &__content {
        font-size: 14 / 320 * 100vw * @factor;
      }
    }

    &__btn {
      height: 50 / 320 * 100vw * @factor;
      font-size: 18 / 320 * 100vw * @factor;
      padding: 11 / 320 * 100vw * @factor 0 0 0;
    }

    &__text {
      &-big {
        font-size: 17 / 320 * 100vw * @factor;
      }
    }

    &__content {
      padding: 20 / 320 * 100vw * @factor 16 / 320 * 100vw * @factor;

      &-blocks {
        .block {
          &:first-child {
            padding-top: 30 / 320 * 100vw * @factor;
          }
        }
      }
    }

    &__title {
      font-size: 18 / 320 * 100vw * @factor;
    }

    &__number {
      font-size: 411 / 320 * 100vw * @factor;
      line-height: 350 / 320 * 100vw * @factor;
    }

    &__next, &__prev {
      height: 60 / 320 * 100vw * @factor;
      font-size: 18 / 320 * 100vw * @factor;
      padding: 18 / 320 * 100vw * @factor 33 / 320 * 100vw * @factor;

      &:after {
        width: 15 / 320 * 100vw * @factor;
        height: 26 / 320 * 100vw * @factor;
        bottom: 17 / 320 * 100vw * @factor;
      }
    }

    &__next {
      &:before {
        border-radius: 0 0 60 / 320 * 100vw * @factor 0;
      }

      &:after {
        right: 16 / 320 * 100vw * @factor;
      }
    }

    &__prev {
      &:before {
        border-radius: 0 0 0 60 / 320 * 100vw * @factor;
      }

      &:after {
        left: 16 / 320 * 100vw * @factor;
      }
    }
  }
  .block {
    font-size: 12 / 320 * 100vw * @factor;
    padding: 16 / 320 * 100vw * @factor;

    &__title {
      font-size: 18 / 320 * 100vw * @factor;
      margin-bottom: 20 / 320 * 100vw * @factor;
    }

    &-white {
      .block {
        &__title {
          font-size: 23 / 320 * 100vw * @factor;
        }
      }
    }
  }
  .car-name {
    font-size: 14 / 320 * 100vw * @factor;

    &__name {
      padding: 6 / 320 * 100vw * @factor 15 / 320 * 100vw * @factor 7 / 320 * 100vw * @factor;
      border-radius: 21 / 320 * 100vw * @factor;
    }

    &__cnt {
      font-size: 11 / 320 * 100vw * @factor;
      padding-right: 16 / 320 * 100vw * @factor;
    }
  }
  .cars {
    height: 70 / 320 * 100vw * @factor;

    &:before, &:after {
      width: 52 / 320 * 100vw * @factor;
    }

    &:after {
      left: 52 / 320 * 100vw * @factor;
      border-width: 93 / 320 * 100vw * @factor 0 0 203 / 320 * 100vw * @factor;
    }

    &-block {
      padding-top: 80 / 320 * 100vw * @factor;
      margin: 0 -16 / 320 * 100vw * @factor;
      height: 150 / 320 * 100vw * @factor;
    }
  }
  .car {
    height: 140 / 320 * 100vw * @factor;
  }
  .carousel-block {
    height: 175 / 320 * 100vw * @factor;
    margin: 0 -16 / 320 * 100vw * @factor;
    padding: 16 / 320 * 100vw * @factor 0 0;
  }
  .tariff {
    margin: 0 0 0 16 / 320 * 100vw * @factor;
    border-radius: 22 / 320 * 100vw * @factor;
    padding: 18 / 320 * 100vw * @factor 10 / 320 * 100vw * @factor 10 / 320 * 100vw * @factor;
    font-size: 12 / 320 * 100vw * @factor;
    height: 145 / 320 * 100vw * @factor;

    &__content {
      padding: 0 13 / 320 * 100vw * @factor;
    }

    &__price {
      font-size: 15.5 / 320 * 100vw * @factor;
      margin-bottom: 10 / 320 * 100vw * @factor;
    }

    &__wait {
      margin-bottom: 5 / 320 * 100vw * @factor;
    }

    .btn {
      font-size: 13.5 / 320 * 100vw * @factor;
      padding: 14 / 320 * 100vw * @factor 17 / 320 * 100vw * @factor 15 / 320 * 100vw * @factor;
      border-radius: 21 / 320 * 100vw * @factor;
    }

    &-data {
      margin-bottom: -15 / 320 * 100vw * @factor;

      &__row {
        margin-bottom: 15 / 320 * 100vw * @factor;
      }
    }
  }

  .filter-cars .filter-tab-cars {
    height: 100%;
  }

  .ps.ps--active-y{
    margin-right: -20 / 320 * 100vw * @factor;
    padding-right: 20 / 320 * 100vw * @factor;
  }
}
