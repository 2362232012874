
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        




































































@import url('../../assets/css/_variables.less');
.vh-100 {
  height: calc(var(--vh, 1vh) * 100);
}

.modal-backdrop {
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  padding: 1em;
}

.modal {
  margin: auto;
  overflow: hidden;
  border-radius: 3.125vw;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 40.4375vw;
  height: auto;
}

.tariffs-margin {
  margin-top: 30px;
}

.remove-padding {
  padding: 0;
}

.modal-footer {
  justify-content: flex-end;
}

.modal-body {
  height: 100%;
  position: relative;
  padding: 0;
}

.btn-close {
  position: absolute;
  top: 0.5vw;
  right: 0.5vw;
  z-index: 100001;
  border: none;
  font-size: 20px;
  margin: 20px 20px;
  cursor: pointer;
  font-weight: bold;
  width: 1.45833333vw;
  height: 1.45833333vw;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOC4zMiIgaGVpZ2h0PSIyOC4zMTIiIHZpZXdCb3g9IjAgMCAyOC4zMiAyOC4zMTIiPgogIDxwYXRoIGQ9Ik0xMzQwLjM2LDI3OS40NzlsMi4xMi0yLjEyMSwyNi4xNiwyNi4xNjMtMi4xMiwyLjEyMVptMCwyNC4wNDIsMjYuMTYtMjYuMTYzLDIuMTIsMi4xMjEtMjYuMTYsMjYuMTYzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNDAuMzQgLTI3Ny4zNDQpIi8+Cjwvc3ZnPgo=)
    no-repeat 50% / contain;
}

.btn-close-padding {
  top: -30px;
}

.btn-green {
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

@media screen and (max-width: 767px) {
  .modal-backdrop {
    padding: 0;
  }
  .modal {
    width: 100vw;
    height: 100%;
    border-radius: 0;
    overflow: visible;
  }

  .btn-close {
    position: fixed;
    width: 5.625vw;
    height: 5.625vw;
    top: 1.375vw;
    right: 1vw;
  }

  .modal-footer {
    padding: 0;
  }

  .tariffs-margin {
    margin: 0;
    padding-top: 40px;
  }

  .btn-close-padding {
    // top: 1px;
  }

  .vh-full {
    height: 100%;
  }
}
