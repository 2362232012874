































































.safety-memo {
  .safety-memo-container {
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (orientation: landscape) and (max-width: 992px) {
      background: unset;
    }
    .safety-memo-note {
      font-family: EuclidCircularA-Regular, sans-serif;
      position: relative;
      width: 40%;
      height: fit-content;
      background: #ffffff;
      border-radius: 20px;
      padding: 37px 40px 40px 40px;
      @media (max-width: 1500px) {
        width: 80%;
      }
      .note-closer {
        position: absolute;
        right: 16px;
        height: 32px;
        width: 32px;
        padding: 9px;
        background: #e6e6ea;
        border-radius: 50%;
        cursor: pointer;
      }
      .memo-title {
        font-weight: 600;
        font-size: 26px;
        line-height: 33px;
      }
      .memo-list {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        padding: 0; // сброс глобального стиля
        margin: 22px 0 0 20px;
      }
      @media only screen and (orientation: landscape) and (max-width: 992px) {
        position: absolute;
        width: 100%;
        height: fit-content;
        bottom: 0;
        border-radius: 20px 20px 0 0;
        overflow-y: auto;
        margin: 0;
        padding: 20px 28px 0 16px;
        .memo-title {
          font-size: 17px;
          font-weight: 600;
        }
      }
      &_mobile {
        position: absolute;
        width: 100%;
        height: fit-content;
        bottom: 0;
        border-radius: 20px 20px 0 0;
        overflow-y: auto;
        margin: 0;
        padding: 20px 28px 0 16px;
        .memo-title {
          font-size: 17px;
          font-weight: 600;
        }
      }
    }
  }
}
