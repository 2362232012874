
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        











body {
  background-color: white !important;
}
