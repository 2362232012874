











































































































































































.service-info-block {
  font-family: EuclidCircularA-Regular, sans-serif;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(38, 38, 38, 0.12);
  border-radius: 24px;
  width: 375px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  .geo-location-wrapper {
    display: none;
    &_mobile {
      display: flex;
    }
    @media screen and (max-width: 768px) {
      position: absolute;
      right: 12px;
      top: -50px;
    }
    @media only screen and (orientation: landscape) and (max-width: 992px) {
      display: none;
    }
  }
  .mechanic-info {
    font-family: EuclidCircularA-Semibold, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .time-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 12px 16px 20px;
    gap: 8px;
    background: #dcf8f2;
    border-radius: 20px;
    margin-bottom: 20px;
    .time-info-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1eae8d;
    }
    .minutes {
      font-style: italic;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #1eae8d;
      margin-right: 4px;
    }
    .minutes-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #6accb5;
    }
  }
  .security-memo {
    position: relative;
    background: #f7f9fa;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px 48px 20px 56px;
    cursor: pointer;
    transition: transform 0.5s;
    touch-action: none;
    .security-memo-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1f2021;
    }
    .security-memo-subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #6d7275;
    }
    .help-icon {
      position: absolute;
      height: 20px;
      width: 20px;
      top: 50%;
      transform: translateY(-50%);
      left: 22px;
    }
    .arrow-icon {
      position: absolute;
      width: 7px;
      height: 11px;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }
  &_mobile {
    box-shadow: 0px -6px 32px rgba(38, 38, 38, 0.12);
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: fit-content;
    transition: transform 0.5s;
    transform: none;
    left: 0;
    top: initial;
    bottom: 0;
    .time-info {
      width: unset;
    }
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      height: 320px;
    }
    &.service-info-block_hidden {
      transform: translateY(calc(100% - 60px));
      @media (max-height: 800px) {
        transform: translateY(calc(80% - 60px));
      }
    }
  }
  @media only screen and (orientation: landscape) and (max-width: 992px) {
    right: 20px;
    bottom: 0;
    left: unset;
    top: unset;
    height: fit-content;
    width: fit-content;
    transform: translateY(0);
    border-radius: 20px 20px 0 0;
    transition: transform 0.5s;
    &.service-info-block_hidden {
      transform: translateY(calc(100% - 60px));
      @media (max-height: 800px) {
        transform: translateY(calc(80% - 60px));
      }
    }
  }
}
