
          @import "~@/assets/css/_variables";
          @import "~@/assets/css/_sizes";
          @import "~@/assets/css/_sizesMobile";
        






























































































@fraction: 1 / 1280 * 100vw;

.qr-download__title {
  font-weight: 700;
  font-size: 16 * @fraction;
  line-height: 24 * @fraction;
  text-transform: none;
}

.qr-download {
  width: 460 * @fraction;
  padding: 40 * @fraction;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24 * @fraction;
  }

  &__tiles {
    display: flex;
    justify-content: space-between;
  }

  &__tile {
    width: 180 * @fraction;
    background-color: @gray16;
    border-radius: 20px;
    transition: background-color 0.3s ease;

    &--text {
      padding: 60 * @fraction 24 * @fraction;
      text-align: center;
    }

    &--copy-success {
      background-color: rgba(220, 248, 242, 1);
    }
  }

  &__text {
    color: @green11;
    font-weight: 500;
    font-size: 14 * @fraction;
    line-height: 20 * @fraction;
  }

  &__qr-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin: 0;
  }

  &__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-width: 32 * @fraction;
    width: 32 * @fraction;
    height: 32 * @fraction;
    border-radius: 50%;
    transform: rotate(45deg);
    background: #e6e6ea;
    border: none;
    cursor: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background: #000;
    }

    &::before {
      width: 20px;
      height: 2px;
    }

    &::after {
      width: 2px;
      height: 20px;
    }
  }
}
