



















































































































































































































































.urgent-service {
  position: relative;
  width: 100vw;
  cursor: default;
  overflow: hidden;
  .blop {
    position: absolute;
    &_top {
      top: 0;
      left: 0;
    }
    &_bottom {
      bottom: 0;
      right: 0;
    }
    &_top_mobile {
      top: -200px;
      left: -200px;
    }
    &_bottom_mobile {
      bottom: 0;
      right: 0;
    }
  }
  .map-container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    &_centering {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .map-loader {
      background: #ffffff;
      border-radius: 16px;
      position: absolute;
      width: 500px;
      height: 188px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      &_mobile {
        width: 375px;
        height: 206px;
      }
    }
    .fallback {
      &_mobile {
        bottom: 0;
      }
    }
  }
  .geo-locator {
    position: absolute;
    right: 20px;
    bottom: 20px;
    @media only screen and (orientation: landscape) and (max-width: 992px) {
      position: absolute;
      right: 440px;
      bottom: 20px;
    }
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}
