


























.geo-location-wrapper {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .geo-location-icon {
    width: 20px;
    height: 20px;
  }
}
