@import "_variables";
@import "_sizes";
@import "_sizesMobile";

*{
  box-sizing: border-box;
}

html {
}
body{
  margin:0;
  padding:0;
  font-family: @Euclid;
  background: @gray;
  color: @black;
  font-size: 16px;
  cursor: none;
  overflow: hidden;
}

::selection {
  background: @green;
}

.mapboxgl-canvas-container.mapboxgl-interactive, .mapboxgl-ctrl-group > button.mapboxgl-ctrl-compass{
  cursor: none;
}

.cursor{
  position: absolute; line-height: 0; background: fade(@green, 40%);
  z-index: 99999999999;
  pointer-events: none;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  .transition(padding);

  &.hover{
    padding: 16px;
    span{
      width: 13px; height: 13px; background: @black;
    }
  }
  span{
    display: inline-block; width: 14px; height: 14px; border-radius: 50%; background: @gray2;
    .transition();
  }
}

img{border:0;}
h1, .h1{font-size: 55px; line-height: 69px; font-weight: normal; margin: 0; padding: 0; font-family: @EuclidMedium;}
h2, .h2{font-size: 60px; font-weight: normal; margin: 0 0 40px; padding: 0; font-family: @EuclidMedium; text-transform: lowercase}
h3, .h3{font-size: 20px; font-weight: normal; margin: 0 0 15px; padding: 0;}

input, textarea, a, button, select{
  outline: none;
}

a{
  color: @black;
  cursor: none;
  .transition();

  &:hover{
    color:@black; text-decoration: none;
  }
}
b, strong{
  font-family: @EuclidMedium; font-weight: normal;
}
.clr{clear:both;}

.transition(@param: all, @time: 0.3s, @type:ease){
  transition: @param @time @type;
}
.backgroundImage(@url, @url2x: '', @url3x: ''){

  background-image: url(@url);

  .mixin2x(@url2x, @url) when (@url2x = '') {
    @url2x: @url;
  }
  .mixin3x(@url3x, @url2x) when (@url3x = '') {
    @url3x: @url2x;
  }

  .mixin2x(@url2x, @url);
  .mixin3x(@url3x, @url2x);

  background-image: -webkit-image-set(url(@url) 1x, url(@url2x) 2x, url(@url3x) 3x);
  background-image: image-set(url(@url) 1x, url(@url2x) 2x, url(@url3x) 3x);
}
.currentMarker {
  background-image: url(../images/dot.svg);
  background-size: cover;
  width: 99px;
  height: 99px;
  border-radius: 50%;
}

#app{
  &.openHeader{
    header{
      top:50px; right: 110px;
    }
    .hamburger{
      &-header {
        margin-top: 6px;

        span {
          transform: rotate(45deg);

          &:last-child {
            transform: rotate(-45deg);
            margin-top: -2px;
          }
        }
      }
    }
  }
}
.wrapper{
  position: fixed; top:0; left: 0; width: 100%; height: 100%;
}
.turn{
  display: none;
}
.page{
  position: fixed; top:0; left: 0; width: 100%; height: 100%; overflow: hidden; z-index: 10;
  .transition(opacity, 0.5s);

  &-Home{
    background: no-repeat center / cover;
    .content{
      display: flex; align-items: flex-end; justify-content: space-between; height: 100%;
      padding-bottom: 88px + 50; position: relative; z-index: 1;
    }
  }
}
.loaded{
  .m10, .m25, .m50, .m75, .m100, .m300{
    will-change: transform;
    transform: translateX(0);
  }
}
.triangle{
  position: fixed; top:0; left: 0; z-index: 110;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 180px 309px 0 0;
  border-color: @green transparent transparent transparent;

  .logo{
    width: 167px; height: 24px; transform: rotate(-30deg); position: absolute; bottom: 87px; left: 22px;
  }
}
.logo{
  width: 258px; height: 38px; display: inline-block;
}

.delimobil-logo {
  background: url(../images/logo.svg) no-repeat 50% / contain;
}

.anytime-logo {
  background: url('../../../public/img/by/anytimelogo.svg') no-repeat 50% / contain;
}
.content{
  width: 1920px - (68*2); margin: 0 auto;
}

.m10{
  transform: translateX(10%);
  .transition(transform, 1s);
}
.m25{
  transform: translateX(25%);
  .transition(transform, 1.3s);
}
.m50{
  transform: translateX(50%);
  .transition(transform, 1.6s);
}
.m75{
  transform: translateX(75%);
  .transition(transform, 1.9s);
}
.m100{
  transform: translateX(100%);
  .transition(transform, 2.2s);
}
.m300{
  transform: translateX(300%);
  .transition(transform, 2.5s);
}

header{
  position: fixed; top:50px; right: 70px - 18; z-index: 99; display: flex; align-items: center;
  .transition();
  a{
    font-size: 18px; line-height: 18px; height: 50px; border-radius: 25px; text-decoration: none; padding: 15px 18px 0; margin: 0 1px; font-family: @EuclidSemibold;
    .transition();

    &:hover, &.active{
      text-decoration: none; color: @gray4; background: @gray5;
    }
  }
  &.hidden{
    top: -100px;
  }
}
footer{
  height: auto; background: @gray7; display: flex; flex-wrap: wrap; position: fixed; bottom: 0; left: 0; z-index: 99; width: 100%; justify-content: space-between; font-size: 16px; font-family: @EuclidMedium;
  .transition();
  .bg {
    position: absolute;
    height: 400%;
    width: 100%;
    background: white;
    left: 0;
    top: 0;
    z-index: -1;
  }
  >div{
    display: flex; height: 100%; align-items: center;
    >div{
      display: flex; height: 100%; align-items: center; padding: 0;
    }
  }
  a{
    color: @black; text-decoration: none; margin: 0 15px; display: inline-block;
    &:hover, &.active{
      text-decoration: underline;
    }
  }
  &.hidden{
    transform: translateY(100%);
  }
}
.medium{
  font-family: @EuclidMedium;
}
.semibold{
  font-family: @EuclidSemibold;
}
.policy{
  width: calc(100% - 16px);
  border-radius: 40px;
  height: 90px;
  background: #28e1b9;
  color: black;
  font-size: inherit;
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px auto 10px 8px;

  animation-duration: 2s;
  animation-name: slidein;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0,0,.58,1);
}

@keyframes slidein {
  0% {
    transform: translateX(45%);
    opacity: 0;

  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

.policy.mobile {
  flex-direction: column;
  font-size: 3.59375vw;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 999;
}
.policy.mobile .but {
  padding: 15px 0;
  width: calc(100% - 50px);
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 400px) {
  .policy.mobile .but {
    height: 30px;
    padding: 7px 0;
  }
}
.policy .policy-text {
  font-size: inherit;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.policy .policy-text div {
  width: 100%;
}
.policy .but {
  border-radius: 40px;
  background: black;
  color: #28e1b9;
  text-align: center;
  padding: 0 115px;
  cursor: pointer;
  user-select: none;
  height: 50px;
  margin-right: 20px;
}
.policy .link {
  text-decoration: underline;
  margin: 0;
}
.menu{
  &-footer{
    background: @gray6;
    padding: 0;
    position: relative;
    .bg {
      background: @gray6;
    }
    a{
      padding: 6px 14px 0; text-decoration: none; margin: 0 1px; height: 34px; border-radius: 17px;
      &:hover, &.active{
        background: @green; text-decoration: none;
      }
    }
  }
  &-additional{
    background: @gray8; padding: 0; font-family: @EuclidSemibold;
    height: 4.58333333vw;
    position: relative;
    .bg {
      background: @gray8;
    }
    a{
      padding: 13px 28px 0; text-decoration: none; margin: 0 1px; height: 48px; border-radius: 24px;
      &:hover, &.active{
        background: @gray9; text-decoration: none;
      }
    }
  }
}
.btn{
  font-size: 38px; line-height: 38px; background: @green; text-transform: lowercase; border-radius: 43px; text-decoration: none; padding: 20px 45px 28px; font-family: @EuclidMedium; display: inline-block; color: @black; text-align: center;
  .transition();

  &-small{
    font-size: 18px; line-height: 18px; border-radius: 22px; text-align: center; padding: 12px 20px 13px;
  }
  &-midi{
    font-size: 17px; line-height: 17px; border-radius: 27px; padding: 18px 30px 19px;
  }
  &-fixed{
    width: 270px;
  }
  &-large{
    font-size: 20px; border-radius: 27px; padding: 7px 30px 10px;
  }

  .icon{
    margin-left: -5px; margin-right: 20px; vertical-align: middle;
  }

  &:hover{
    background: @white;
    &.btn-hover-gray{
      background-color: @gray10;
    }
  }
}
.icon{
  display: inline-block; background: no-repeat center / contain;
  &-tg{
    background-image: url(../images/icon_tg-2.svg); width: 17px; height: 14px;
  }
}

.background-video-image {
  position: absolute; width: 100%;
  height: 100%;
  background-size: 1340px;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (orientation: landscape) and (min-width: 1340px) {
    background-size: cover;
  }
}

.background-video {
  position: absolute;
  top: 0; left: 0;
  z-index: 1;
  height: 100%; width: 100%;
  overflow: hidden;
  background: @gray13;

  display: flex;
  align-items: center;
  justify-content: center;

  .screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto;
    opacity: 0;
    .transition(opacity, 1s);

    &.active{
      opacity: 1;
    }
  }
}
.dn{
  display: none;
}
.download{
  position:fixed; z-index: 11;  bottom: 137px; right: 68px; opacity: 1;
  .transition(all, 1.5s);

  &-right{

  }
  &-left{
    right: 100%!important; transform: translateX(100%);
  }
  &-hide{
    right: 110%!important;
  }
}
.number{
  position: absolute; color: @white; opacity: .4; font-size: 1363px; line-height: normal; top:50%; left: 50%; transform: translateY(-50%) translateX(-45%); font-family: @EuclidMedium;
}
.pagination{
  position: absolute; bottom: 137px; left: 50%; display: flex; z-index: 11; height: 155px; align-items: center; margin-left: 75px;
  &__item{
    font-size: 25px; font-family: @EuclidMedium; display: inline-block; border-radius: 15px; width: 38px; text-align: center; margin-right: 5px;
    .transition();

    &.active, &:hover{
      background: @green;
    }
  }
}
.navigation{
  position: absolute; bottom: 137px; right: 68px; display: flex; z-index: 11; background: @green; border-radius: 50px;
  .transition(all, 1.5s);
}
.prev, .next{
  display: inline-block; width: 155px; height: 155px; background: @green url(../images/arrow.svg) no-repeat center; border-radius: 0 50px 50px 0;
  .transition();

  &.disable{
    background-image: url(../images/arrow-green-light.svg);
  }
}
.prev{
  transform: rotate(180deg); background-color: @green2;
  &.disable{
    background-image: url(../images/arrow-green.svg);
  }
}
.title{
  font-size: 110px; font-family: @EuclidMedium; margin-bottom: 40px;
  &-big{
    font-size: 150px;
  }
  &-verybig{
    font-size: 160px;
  }
}
.text{
  font-size: 26px; font-family: @EuclidMedium;
}
p{
  padding: 0; margin: 0;
  + p{
    margin-top: 20px;
  }
}
.steps-page{
  display: flex; flex-direction: column; justify-content: center; height: 100%; padding-left: 76px; width: 50%; margin-left: 50%; position: relative; padding-bottom: 10%;
}
.social{
  display: flex; margin-right: 15px;
  &__icon{
    display: inline-block; width: 44px; height: 34px; background: @white no-repeat center; border-radius: 17px; margin: 0 8px 0 0;
    .transition();
    &-fb{
      background-image: url(../images/icon_fb.svg);
    }
    &-tw{
      background-image: url(../images/icon_tw.svg);
    }
    &-youtube{
      background-image: url(../images/icon_youtube.svg);
    }
    &-inst{
      background-image: url(../images/icon_inst.svg);
    }
    &-vk{
      background-image: url(../images/icon_vk.svg);
    }
    &-tg{
      background-image: url(../images/icon_tg.svg);
    }

    &:hover{
      background-color: @green;
    }
  }
}
.app-screen{
  position: absolute; right: 50%; bottom:0; width: 50%; background: no-repeat right center / contain; height: 80%;
}
.app-screen.m50 {
  left: -10%;
  top: -4%;
}
.vue-map-container{
  position: absolute!important; top:0; left: 0;
}
.map-controls{
  position: absolute; z-index: 9; display: flex; bottom: 68px; left: 68px;
  &__zoom{
    width: 94px; height: 94px; background: @green2; display: flex; justify-content: center; align-items: center; font-size: 26px; line-height: 26px; font-family: @EuclidMedium; text-decoration: none;
    .transition();

    &:hover{
      //background: @green;
    }

    &-plus{
      border-radius: 0 50% 50% 0; background: @green;
    }
    &-minus{
      border-radius: 50% 0 0 50%;
    }
  }
}
.filter{
  position: absolute; z-index: 9; top:147px; right: 68px; background: @white; box-shadow: 0 0 20px fade(@black, 4%); padding: 27px 25px 25px; border-radius: 43px;
  min-width: 400px;
  &-tab{
    +.filter-tab{
      padding-top: 25px;
    }
  }
}
.carousel-block{
  background: @green3; margin: 0 -25px -25px; border-radius: 0 0 43px 43px; overflow: hidden; height: 230px;
  max-width: 400px;
  animation-delay: .1s;
  -webkit-animation-delay: .1s;
}
.owl-stage{
  width: 99999px;
}
.owl-carousel-tariffs{
  display: none;
  .owl-stage{
    padding-left: 0!important;
  }
  &.active{
    display: block;
  }
  .owl-carousel{
    .transition(opacity);
  }
}
.owl-carousel-popup{
  .owl-stage-outer{
    margin-bottom: -60px; padding-bottom: 60px;
    margin-top: -45px; padding-top: 45px;
  }
}
.tariff{
  margin: 22px 0 21px 20px; background: @white; border-radius: 30px; padding: 18px 10px 10px; font-size: 15px; font-family: @EuclidMedium; height: 186px; display: flex; flex-direction: column; justify-content: space-between;
  .btn{
    width: 100%;
  }
  &__content{
    padding: 0 13px;
  }
  &__price{
    font-size: 22px; text-transform: uppercase; margin-bottom: 14px;
  }
  &__wait{
    font-family: @EuclidSemibold; margin-bottom: 5px;
  }
  &__free{
    color: @gray11;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &-data{
    margin-bottom: -15px;
    &__row{
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      > div{
        &:last-child{
          font-family: @EuclidSemibold;
        }
      }
    }
  }
}
.block{
  font-size: 20px; padding: 40px 70px 35px;
  &__title{
    font-size: 40px; font-family: @EuclidMedium; margin-bottom: 20px;
  }
  &-white{
    background: @white;
  }
}
.car-names{
  margin: 7px 0 0 0; max-height: 200px;
}
.car-name{
  font-size: 20px; display: flex; justify-content: space-between; margin-right: 20px; margin-bottom: 8px; align-items: center;
  &__name{
    padding: 7px 27px 8px; text-decoration: none; color: @gray3; border-radius: 21px;
    .transition();
    &:hover{
      text-decoration: none; color: @gray3;
    }
  }
  &__cnt{
    opacity: 0.5; font-family: @EuclidMedium; font-size: 16px;
  }
  &:hover, &.active{
    .car-name__name{
      background: @gray10;
    }
  }
}
.cars{
  background: @gray12; position: relative; height: 93px;
  &:before, &:after{
    content: ""; position: absolute; display: inline-block; top:0; left:0; width: 52px; height: 100%;
  }
  &:before{
    background: @green4;
  }
  &:after{
    width: 0; left: 52px;
    height: 0;
    border-style: solid;
    border-width: 93px 0 0 203px;
    border-color: transparent transparent transparent @green4;
  }
  &-block{
    overflow: hidden; padding-top: 95px; margin: 0 -25px;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
  }
}
.car{
  width: 95%; height: 163px; background: no-repeat center / contain; position: absolute; bottom: 2px; /*left: 100%;*/ left:50%; z-index: 1; transform: translateX(-50%);
}
.mgl-map-wrapper, .mapboxgl-map{
  width: 100%; height: 100%;
}
.marker-btn{
  display: none;
}
.fancybox {
  &-content {
    background: none; padding: 0;
  }
  &-bg {
    background: @gray14;
  }
  &-is-open{
    .fancybox-bg{
      opacity: .7;
    }
  }
  &-close{
    background: url(../images/close.svg) no-repeat center / contain; width: 28px; height: 28px; top:35px; right: 48px; position: absolute; display: inline-block; z-index: 1;
    svg{
      display: none;
    }
  }
  &-slide--html{
    .fancybox-close-small{
      padding: 0; top:35px; right: 48px;
    }
  }
}
.fancybox-can-pan .fancybox-content, .fancybox-can-swipe .fancybox-content{
  cursor: none;
}
.popup{
  overflow: hidden; border-radius: 60px; background: @white; width: 930px; position: relative;
  &__tabs{
    display: flex;
    &__content{}
  }
  &__tab{
    width: 50%; padding: 10px 70px 35px; height: 130px; font-size: 40px; font-family: @EuclidMedium; text-transform: lowercase; background: @gray15; text-decoration: none;
    &.active{
      background: @white;
      /*border-bottom: 1px solid @green2;*/
    }
    &__content{
      display: none; font-size: 22px; padding: 45px 70px 160px;
      &.active{
        display: block;
      }
    }
  }
  &__text{
    position: relative;
    &-big{
      font-size: 25px;
    }
  }
  &__btn{
    display: none;
  }
  &__title{
    font-size: 30px;
  }
  &__content{
    position: relative;
    &-blocks {
      background: @gray14; padding: 0!important;
    }
  }
  &__number{
    position: absolute; font-size: 490px; font-family: @EuclidMedium; line-height: 310px; top:0; right: -25px; color: @gray15;
  }
  &__next, &__prev{
    height: 160px; display: flex; position: absolute; bottom: 0; color: @black; font-size: 25px; font-family: @EuclidMedium; width: 50%; align-items: center; padding: 60px 75px 0; text-decoration: none;
    &:before{
      content:""; background: @white; width: 100%; height: 60px; display: inline-block; bottom: 100px; position: absolute; left: 0;
    }
    &:after{
      content:""; background: no-repeat center / contain; width: 22px; height: 39px; position: absolute; bottom:33px;
    }
  }
  &__next{
    left: 50%; background: @green; color: @green2;
    &:hover{
      color: @green2;
    }
    &:before{
      border-radius: 0 0 60px 0;
    }
    &:after{
      background-image: url(../images/arrow-green-light.svg); right: 70px;
    }
  }
  &__prev{
    left:0; background: @green5; color: @green; justify-content: flex-end;
    &:hover{
      color: @green;
    }
    &:before{
      border-radius: 0 0 0 60px;
    }
    &:after{
      background-image: url(../images/arrow-green.svg); left:70px;
      transform: rotate(180deg);
    }
  }
}
.carouselPrevActive{
  .popup__prev{
    color: @black;
    &:after{
      background-image: url(../images/arrow.svg);
    }
  }
}
.carouselNextActive{
  .popup__next{
    color: @black;
    &:after{
      background-image: url(../images/arrow.svg);
    }
  }
}
.half{
  display: flex; align-items: center; height: 100%;
  &__text{
    width: 50%;  padding-right: 50px;
  }
  &__pic{
    position: absolute; top:0; left: 50%; width: 50%; height: 100%; background: no-repeat center / cover;
  }
  &-reverse{
    .half {
      &__text {
        margin-left: 50%;
        padding-right: 0;
        padding-left: 75px;
      }

      &__pic {
        left: 0;
      }
    }
  }
  &-about{
    .half{
      &__text{
        font-family: @EuclidLight; font-size: 58px;
        &-small{
          font-size: 43px;
        }
      }
    }
    .scroll-area, .magic-area {
      max-height: 70vh;
    }
  }
  &-members{
    .scroll-area{
      max-height: 60vh;
    }
    .half{
      &__text{
        font-size: 26px;
        padding-bottom: 150px;
      }
    }
  }
  &-contacts{
    .scroll-area {
      max-height: 43vh;
    }
    .half{
      &__text{
        font-size: 22px;
      }
    }
  }
  &-press{
    .scroll-area {
      max-height: 57vh;
    }
    .half{
      &__text{
        height: 57vh; overflow: hidden; display: flex; flex-direction: column; justify-content: center;
        padding-bottom: 0;
        margin-bottom: 190px;
      }
    }
  }
  &-article{
    .scroll-area {
      max-height: 71vh;
    }
    .half{
      &__text{
        padding-bottom: 30px;
      }
    }
  }
}
.vertical-tabs{
  margin-left: -18px; margin-bottom: 30px;
  &__content{
    height: 450px; position: relative;
  }
}
.vertical-tab{
  font-family: @EuclidMedium; font-size: 26px; padding: 4px 18px 5px; margin-bottom: 10px; display: inline-block; border-radius: 20px;
  .transition();

  &.active, &:hover{
    background: @white;
  }
  &__content{
    position: absolute; top:0; left: 0; width: 100%;
  }
}
.members{
  position: absolute; bottom: 88px; left: 50%; z-index: 10; line-height: 0; white-space: nowrap;
  .scroll-area{
    max-width: 50vw;
  }
}
.member{
  width: 138px; height: 138px; display: inline-block; position: relative;
  &__pic{
    background: no-repeat center / cover; width: 100%; height: 100%;
  }
  &__voile{
    position: absolute; top:0; left: 0; width: 100%; height: 100%;
    .transition();
    &:hover{
      background: fade(@green, 60%);
    }
  }
  &__name{
    font-size: 46px; font-family: @EuclidMedium; margin-bottom: 30px;
  }
}
.ps__scrollbar-y-rail{background-color:transparent!important;}
.ps__scrollbar-x-rail{background-color:transparent!important;}
.ps{
  &__container{
    padding-right: 10px;
  }
  &__scrollbar{
    &-y, &-x {
      background: @white !important;
      z-index: 1;
    }
  }
  &.psAlways{
    .ps__scrollbar-x-rail{
      opacity: 1!important;
    }
    .ps__scrollbar-y-rail{
      opacity: 1!important;
    }
  }
  &.psGreen{
    .ps__scrollbar{
      &-y, &-x {
        background: @green !important;
      }
    }
  }
  &:hover{
    .ps__scrollbar-x-rail{
      opacity: 1!important;
    }
    .ps__scrollbar-y-rail{
      opacity: 1!important;
    }
  }
}

.hamburger{
  .transition(all, 1s);
  &-header {
    display: block;
    position: absolute;
    line-height: 0;
    z-index: 999;
    top: 68px;
    right: 68px;
    width: 28px;

    span {
      display: block;
      width: 100%;
      background: @black;
      height: 3px;
      .transition();

      &:last-child {
        margin-top: 11px;
      }
    }

    &.hidden {
      top: -100px;
    }
  }
}
.contacts-block{
  margin-top: 55px;

  &__btns{
    .btn{
      +.btn{
        margin-left: 15px;
      }
    }
    &-2{
      margin-left: -20px; margin-top: -25px;
      .btn{
        margin: 25px 0 0 20px;
        +.btn{
          margin-left: 20px;
        }
      }
    }
  }
  &__title{
    margin-bottom: 50px; font-family: @EuclidMedium;
    b{
      font-family: @EuclidBold;
    }
  }
  &__text{
    &-small{
      font-size: 18px; line-height: 30px;
    }
  }
}
.articles{
  margin-left: -16px;
}
.article{
  &__link{
    font-size: 60px; line-height: 55px; font-family: @EuclidMedium; margin-bottom: 40px; padding: 8px 16px 13px;
    .transition(background);
    &:hover{
      background: @green;
    }
    a{
      text-decoration: none;
      transition: none;
      &:hover{
        text-decoration: none;
      }
    }
  }
  img{
    max-width: 100%;
  }
  li{
    +li{
      margin-top: 15px;
    }
  }
}
.voile{
  position: absolute; display: flex; top:0; left: 0; background: fade(@green, 80%); width: 100%; height: 100%; z-index: 98; justify-content: center; align-items: center;
  &.full{
    background: @green;
  }
}
.article{
  font-size: 22px;
  &__title{
    font-size: 46px; font-family: @EuclidMedium; margin-bottom: 30px;
  }
}
.back{
  position: absolute; left: 50%; margin-left: 75px; text-decoration: none; font-size: 25px; font-family: @EuclidMedium; top:60px;
}
.pages{
  display: none;
}
.ps.ps--active-y{
  margin-right: -20px;
  padding-right: 20px;
  position: relative;
}

.hideMobile{}
.hideDesktop{display: none;}

.sizes(1);

@ratio2010: ~"(min-aspect-ratio: 20/10)";

@media screen and @ratio2010 and (orientation: landscape) and (min-width: 768px) {
  .sizes(.85);

  .car-names {
    height: 155 / 1920 * 100vw * .85;
  }
}

@ratio2510: ~"(min-aspect-ratio: 25/10)";

@media screen and @ratio2510 and (orientation: landscape) and (min-width: 768px) {
  .sizes(.8);

  .car-names {
    height: 105 / 1920 * 100vw * .8;
  }
}

@ratio3010: ~"(min-aspect-ratio: 30/10)";

@media screen and @ratio3010 and (orientation: landscape) and (min-width: 768px) {
  .sizes(.75);

  .car-names {
    height: 105 / 1920 * 100vw * .75;
  }
}

/*@media only screen and (max-width: 1919px), only screen and (max-height: 1079px) {

}*/

@media screen and (max-width: 767px) {
  .hideMobile{display: none!important;}
  .hideDesktop{display: initial;}
  body{
    font-size: 16px;
    -webkit-text-size-adjust: none;
  }
  #app {
    cursor: auto;
    &.openHeader {
      header {
        top:0;
      }
      .page{
        opacity: 0;
      }
      .footer-mobile{
        transform: translateY(100%);
      }
      .hamburger {
        &-header {
          margin-top: 6px;

          span {
            &:last-child {
              margin-top: -2px;
            }
          }
        }
      }
    }
    &.openFooter{
      .page{
        opacity: 0;
      }
      .hamburger{
        &-header {
          top: -50px;
        }
      }
      footer{
        bottom:0;
      }
    }
  }
  .mobile-header-menu-parts{
    &__part{
      position: absolute;
      .transition(all, 1.5s);
      &-1{
        top:-220px; left:-400px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 220px 400px 0 0;
        border-color: @white transparent transparent transparent;
      }
      &-2{
        bottom: -75px; left: -155px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 75px 0 0 155px;
        border-color: transparent transparent transparent @white;
      }
      &-3{
        bottom: -125px; right: -210px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 125px 210px;
        border-color: transparent transparent @green transparent;
      }
    }
    &.active{
      .mobile-header-menu-parts {
        &__part {
          &-1 {
            top:0; left:0;
          }

          &-2 {
            bottom: 0; left: 0;
          }

          &-3 {
            bottom:0; right: 0;
          }
        }
      }
    }
  }
  .mobile-footer-menu-parts{
    position: absolute;
    &__part{
      position: fixed;
      .transition(all, 1.5s);
      &-1{
        top:-235px; left:-420px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 235px 420px 0 0;
        border-color: @green transparent transparent transparent;
      }
      &-2{
        bottom: -145px; right: -255px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 145px 255px;
        border-color: transparent transparent @green7 transparent;
      }
    }
    &.active{
      .mobile-footer-menu-parts {
        &__part {
          &-1 {
            top:0; left:0;
          }

          &-2 {
            bottom: 0; right: 0;
          }
        }
      }
    }
  }
  footer{
    position: fixed; overflow: hidden; width: 100%; height: 100%; bottom:-100%; left: 0; flex-direction: column; justify-content: center; background: @green6;
    .transition(all, 1s);

    >div{
      position: relative; flex-direction: column; justify-content: center;
    }
    a{
      font-size: 16px; line-height: 16px; height: 27px; border-radius: 14px; padding: 4px 25px 0; margin: 8px 0; text-decoration: none;
      &:hover, &.active{
        background: @green8; text-decoration: none;
      }
      &.big{
        font-size: 16px; line-height: 16px; height: 50px; border-radius: 25px; padding: 16px 35px 0; margin: 15px 0 10px; background: @white;
      }
    }
  }
  header{
    position: fixed; width: 100%; height: 100%; top:-100%; left: 0; flex-direction: column; justify-content: center;
    .transition(all, 1s);

    a{
      font-size: 18px; line-height: 18px; height: 50px; border-radius: 25px; padding: 15px 18px 0; margin: 25px 0;
    }
  }
  .cursor{
    display: none;
  }
  .link{
    cursor: pointer;
  }
  .content{
    width: 288px;
  }
  .triangle{
    border: none;
    .transition();
    &.hidden{
      top: -60px;
    }
    .logo{
      width: 116px; height: 18px; transform: none; bottom: auto; left: 16px; top:14px;
    }
  }
  .hamburger {
    &-header {
      top: 16px;
      right: 16px;
      width: 22px;

      span {
        height: 2px;

        &:last-child {
          margin-top: 8px;
        }
      }
    }
    &-footer{
      position: relative; width: 18px;

      span{
        width: 18px;
        display: block;
        background: @black;
        height: 2px;

        &:last-child{
          width: 13px; margin-top: 8px;
        }
      }
    }
    &-close {
      display: block;
      position: absolute;
      line-height: 0;
      z-index: 999;
      top: 16px;
      right: 16px;
      width: 22px;
      margin-top: 6px;

      span {
        transform: rotate(45deg); height: 2px;         display: block;
        background: @black;

        &:last-child {
          transform: rotate(-45deg);
          margin-top: -2px;
        }
      }
    }
  }
  .footer{
    &-mobile{
      background: @gray6; height: 48px; display: flex; flex-wrap: wrap; position: fixed; bottom:0; left: 0; width: 100%; z-index: 11; font-size: 11.5px; font-family: @EuclidMedium; justify-content: space-between; align-items: center; padding: 0 16px;
      .transition(transform, 1s);

      &.hidden{
        transform: translateY(100%);
      }
    }
  }
  .voile{
    img{
      width: 50px; height: 50px;
    }
  }
  h1, .h1 {
    font-size: 27px;
    line-height: 30px;
  }
  .page{
    overflow-y: scroll;
    //-webkit-overflow-scrolling: touch;
    &-Map{
      overflow-y: hidden;
    }
    &-About, &-Team, &-Contacts, &-Press, &-Article{
      &:before{
        content: ""; position: fixed; top:0; left: 0; width: 100%; background: @gray; height: 44px; z-index: 11;
      }
    }
    &-Home{
      padding-bottom: 48px; background: none!important;
      .content{
        padding-bottom: 0; flex-direction: column; align-items: center; justify-content: center;
      }
      h1, .h1 {
        text-align: center; margin-bottom: 25px;
      }
      .download{
        right: auto;
        position: absolute; left:50%; bottom:0; margin-left: -50vw;
      }
      .btn{
        border-radius: 0; width: 100vw; height: 58px; font-size: 18px; line-height: 18px; padding: 18px 0 0;
      }
    }
    &-How{
      .page {
        overflow: hidden;
      }
      .navigation{
        bottom: 0;
      }
      .download{
        right: auto;
        position: absolute; left:16px; bottom:58px; margin-left:0;
      }
      .btn{
        font-size: 19px; line-height: 19px; right: auto; left: 16px; bottom:60px; height: 44px; padding: 12px 20px 0; border-radius: 22px;
      }
      .text{
        br{
          display: none;
        }
      }
    }
    &-Team{
      .half__pic{
        background-position: center top -10px;
        background-size: 190%;
      }
    }
    &-Rules {
      &:before {
        content: '';
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        height: 60px;
        background: white;
        z-index: 1;
      }

      .half-press .half__text {
        padding-top: 0;
      }
    }
  }
  .mobileHomeBg{
    width: 100vw; height: 70vw; background: no-repeat center / 150% auto; margin-bottom: -20px;
  }
  .app-screen{
    right: -17%;
    width: 135%;
    height: 135%;
    bottom: -15%;
  }
  .navigation {
    bottom: 48px; width: 100%; justify-content: flex-end; border-radius: 0; position: fixed;
    right: 0;
  }
  .prev, .next {
    width: 70px;
    height: 48px;
    border-radius: 0;
    background-size: 11px auto;
  }
  .pages{
    position: absolute; top:50%; transform: translateY(-50%); left: 16px; font-size: 19px; font-family: @EuclidMedium; display: block;
  }
  .steps-page{
    width: 100%; margin-left: 0; padding: 40px 16px 48px; justify-content: flex-start;

    .text{
      width: 650px;
      span{
        display: block;
      }
    }
  }
  .title{
    font-size: 40px; margin-bottom: 13px;
  }
  .text{
    font-size: 12px;
  }
  p{
    +p{
      margin-top: 10px;
    }
  }
  .number{
    font-size: 605px;
    transform: translateY(-50%) translateX(-25%);
  }
  .half{
    display: block; height: auto;
    &__text{
      width: 100%;
      margin-left: 0;
      padding-left: 0; padding-right: 0;
      padding-bottom: 110px; padding-top: 15px;

      &-absolute{
        height: auto; position: relative;
        padding-bottom: 0!important;
        >div{
          position: relative; height: auto;
          padding-bottom: 0; padding-left: 0;
        }
      }
    }
    &__pic{
      position: relative; top:0; height: 185px; margin-top: 44px;
      width: 100vw;
      margin-left: -50vw; left: 50%;
    }
    &-reverse{
      .half {
        &__text {
          margin-left: 0;
          padding-left: 0;
        }
        &__pic{
          left: 50%;
        }
      }
    }
    &-about{
      .half{
        &__text{
          font-size: 12.5px;
          &-small{
            font-size: 11.5px;
          }

          br{
            display: none;
          }
        }
      }
      .scroll-area, .magic-area {
        max-height: none;
      }
    }
    &-members{
      .scroll-area{
        max-height: none;
      }
      .half{
        &__text{
          font-size: 12.5px;
          padding-bottom: 110px;
        }
        &__pic{
          width: 250px;
          &-next{
            width: 70px; position: absolute; top:0; margin-left: 0; left: 250px; overflow: hidden;
            .transition();
            .half__pic{
              left: 0; margin-left: 0;
            }
            &.inAction{
              opacity: 0;
            }
          }
        }
      }
    }
    &-contacts{
      .scroll-area {
        max-height: none;
      }
      .half{
        &__text{
          font-size: 13.5px;
          padding-bottom: 110px;
          padding-top: 245px;
        }
        &__pic{
          margin-top: 96px; position: absolute; height: 135px;
        }
      }
    }
    &-press{
      .scroll-area {
        max-height: none;
      }
      .half{
        &__text{
          padding-top: 44px; height: auto;
          padding-bottom: 110px;
          margin-bottom: 0;
        }
      }
    }
    &-article{
      .scroll-area {
        max-height: none;
      }
      .half{
        &__text{
          padding-bottom: 110px;
        }
      }
    }
  }
  .members{
    display: none;
  }
  .member{
    &__name{
      font-size: 13px; margin-bottom: 15px;
    }
  }
  .vertical-tabs{
    &__content{
      height: auto;
    }
  }
  .vertical-tab{
    &__content{
      position: absolute; left: -16px; top:0; width: 100vw; //min-height: 100vh;
      padding: 245px 16px 110px;
    }
  }
  .contacts-block{
    position: fixed; bottom: 48px; left: 0; width: 100%; margin-top: 0;
    &__title{
      padding: 0 16px;
    }
    .btn{
      border-radius: 0; margin: 0; height: 48px; width: 100%; font-size: 18px; line-height: 18px; padding: 14px 0 0;
      &-midi{
        font-size: 11px; line-height: 11px; width: 50%;  padding: 18px 0 0; background: @green2;
      }
      i{
        display: none;
      }
      +.btn{
        margin-left: 0; background: @green;
      }
    }
    &__text{
      &-small{
        font-size: 11.5px; line-height: 17px;
      }
    }
    &__btns{
      display: flex; left:0; width: 100vw; position: relative;
      &-2{
        flex-wrap: wrap; margin-left: 0; margin-top: 0;
        .btn{
          &-fixed{
            font-size: 13px; line-height: 13px; width: 50%;  padding: 18px 0 0;
          }
          &:nth-child(1){
            background: @green2;
          }
          &:nth-child(2){}
          &:nth-child(3){
            background: @green9;
          }
          &:nth-child(4){
            background: @green10;
          }
        }
      }
    }
  }
  .articles {
    margin-left: -16px; width: 100vw;
  }
  .article {
    &__link {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 0; margin-top: 25px;
      padding: 1px 16px 5px;
    }

    font-size: 11.5px;

    &__title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .back {
    margin-left: 0;
    font-size: 18px;
    top: auto; bottom:48px; position: fixed; width: 100%; font-family: @EuclidMedium; padding: 10px 0 0 0; background: @green; left: 0; text-align: center; height: 48px; z-index: 11;
  }
  .select-city{
    position: absolute; left:16px; top:37px; z-index: 11; font-size: 12px; font-family: @EuclidMedium; border: none; background: transparent; padding: 0; margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand {
      display: none;
    }
    .transition();
    &.hidden{
      top:-23px;
    }
  }
  .select-filter{
    border-radius: 15px; border: none; font-size: 24px; font-family: @EuclidSemibold; padding: 7px 15px 9px;  width: 100%; position: relative; background: @gray10 url(../images/arrow-down.svg) no-repeat right 15px center;
    background-size: 13px auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand {
      display: none;
    }
    &.small{
      font-size: 20px; font-family: @EuclidMedium;
    }
  }
  .filter {
    top: auto; bottom: 0;
    right: -3vw;
    box-shadow: 0 0 20px fade(@black, 4%);
    padding: 27px 26px 25px;
    border-radius: 50px 50px 0 0;
    width: 106vw;

    &-cars{
      bottom: -380px;
      &.open{
        bottom: -225px;
        &.full{
          bottom: -25px;
          .filter-tab-cars{
            overflow-y: scroll;
          }
        }
      }
      .filter-tab-cars{
        height: 380px; padding-top: 25px!important; padding-right: 16px; padding-left: 16px; margin: 0 -16px;
        overflow-y: hidden;
      }
      &.moving{
        .transition(all, 0.3s);
      }
    }

    &-tab {
      +.filter-tab {
        padding-top: 18px;
      }
    }
    &__drag{
      margin: -27px auto 0;
      text-align: center;
      line-height: 0;
      padding: 13px 0 18px;
      span{
        width: 55px; height: 4px; border-radius: 2px; background: @gray; display: inline-block;
      }
    }
  }
  .btn{
    &-small {
      font-size: 13px;
      line-height: 13px;
      border-radius: 14px;
      padding: 6px 17px 7px;
    }
  }
  .fancybox {
    &-slide{
      padding: 0;
      -webkit-overflow-scrolling: auto;
    }
    &-close {
      width: 18px;
      height: 18px;
      top: 14px;
      right: 16px;
    }
    &-content {
      height: 100%;
      -webkit-overflow-scrolling: auto;

      .triangle {
        display: none;
      }
    }
  }
  .popup {
    position: absolute;
    border-radius: 0;
    width: 100vw;
    height: 100%;

    &__tab {
      padding: 50px 0 0 16px; text-align: left;
      height: 80px;
      font-size: 18.5px;

      &__content {
        font-size: 14px;
        padding: 0;
        .popup__content-scroll{
          height: ~"calc(100vh - 80px)";
        }
      }
    }
    &-ok{
      .popup__content-scroll{
        height: ~"calc(100vh - 50px)";
      }
    }
    &__btn{
      position: absolute; bottom: 0; left: 0; width: 100%; height: 50px; background: @green; text-align: center; font-size: 18px; font-family: @EuclidMedium; padding: 11px 0 0 0;
    }

    &__text {
      &-big {
        font-size: 17px;
      }
    }

    &__content{
      padding: 20px 16px;
      &-scroll {
       overflow-y: scroll; height: 100vh;
      }
      &-blocks {
        background: @gray14; padding: 0!important;
        .block {
          &:first-child {
            padding-top: 30px;
          }
        }
      }
    }
    &__title {
      font-size: 18px;
    }
    &__btn{
      display: block;
    }
    &__number {
      font-size: 411px;
      line-height: 350px;
      right: 50%;
      transform: translateX(50%);
    }

    &__next, &__prev {
      height: 60px;
      font-size: 18px;
      padding: 18px 33px; z-index: 10;

      &:before {
        display: none;
      }

      &:after {
        width: 15px;
        height: 26px;
        bottom: 17px;
      }
    }

    &__next {
      &:before {
        border-radius: 0 0 60px 0;
      }

      &:after {
        right: 16px;
      }
    }

    &__prev {
      &:before {
        border-radius: 0 0 0 60px;
      }

      &:after {
        left: 16px;
      }
    }
  }
  .block{
    font-size: 12px; padding: 16px;
    &__title{
      font-size: 18px; margin-bottom: 20px;
    }
    &-white{
      .block{
        &__title{
          font-size: 23px;
        }
      }
    }
  }
  .owl-carousel-popup {
    .owl-item{
      height: ~"calc(100vh - 140px)";
    }
    .owl-stage-outer {
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }
  .car-names {
    margin: 0;
    height: auto;
  }
  .car-name {
    font-size: 14px;
    margin-right: 0;
    margin-bottom: 0;

    &__name {
      padding: 6px 15px 7px;
      border-radius: 21px;
    }

    &__cnt {
      font-size: 11px; padding-right: 16px;
    }
  }
  .cars {
    height: 70px;

    &:before, &:after {
      width: 52px;
    }

    &:after {
      left: 52px;
      border-width: 93px 0 0 203px;
    }

    &-block {
      padding-top: 80px;
      margin: 0 -16px;
      height: 150px;
    }
  }
  .car {
    height: 140px;
  }
  .carousel-block{
    height: 175px; border-radius: 0; margin: 0 -16px; padding: 16px 0 0;
    max-width: none;
  }
  .tariff {
    margin: 0 0 0 16px;
    border-radius: 22px;
    padding: 18px 10px 10px;
    font-size: 12px;
    height: 145px;

    &__content {
      padding: 0 13px;
    }

    &__price {
      font-size: 15.5px;
      margin-bottom: 10px;
    }

    &__wait {
      margin-bottom: 5px;
    }
    .btn{
      font-size: 13.5px; padding: 14px 17px 15px; border-radius: 21px;
    }

    &-data{
      margin-bottom: -15px;
      &__row{
        margin-bottom: 15px;
      }
    }
  }
  .ps.ps--active-y{
    margin-right: -20px;
    padding-right: 20px;
  }


  .sizesMobile(1);
}

@ratio4060: ~"(min-aspect-ratio: 40/60)";
@media screen and @ratio4060 and (max-width: 767px) {
  .filter {
    height: 100%;
    top: 55%;
    transition: top 1s ease;

    &-cars {
      top: 80%;
      padding-bottom: 0;

      &.open {
        top: 55%;

        &.full {
          top: 0;

          .filter-wrapper {
            overflow-y: scroll;
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 439px) and (max-width: 767px) {
  .turn{
    display: flex; position: fixed; z-index: 99999999999; background: @green; justify-content: center; align-items: center; text-transform: uppercase; font-size: 20px; top:0; left: 0; width: 100%; height: 100%; font-weight: bold;
  }
}

path.leaflet-interactive {
  animation: fadeIn;
  -webkit-animation: fadeIn;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
}
