.sizes(@factor) {
  body {
    font-size: 16 / 1920 * 100vw * @factor;
  }

  /*.cursor {
    &.hover {
      padding: 16 / 1920 * 100vw * @factor;

      span {
        width: 13 / 1920 * 100vw * @factor;
        height: 13 / 1920 * 100vw * @factor;
      }
    }

    span {
      width: 14 / 1920 * 100vw * @factor;
      height: 14 / 1920 * 100vw * @factor;
    }
  }*/

  h1, .h1 {
    font-size: 55 / 1920 * 100vw * @factor;
    line-height: 69 / 1920 * 100vw * @factor;
  }
  h2, .h2 {
    font-size: 60 / 1920 * 100vw * @factor;
    margin: 0 0 40 / 1920 * 100vw * @factor;
  }
  h3, .h3 {
    font-size: 36 / 1920 * 100vw * @factor;
    margin: 0 0 36 / 1920 * 100vw * @factor;
  }
  .currentMarker {
    /*width: 99 / 1920 * 100vw * @factor;
    height: 99 / 1920 * 100vw * @factor;*/
  }
  #app {
    &.openHeader {
      header {
        top: 50 / 1920 * 100vw * @factor;
        right: 110 / 1920 * 100vw * @factor;
      }

      .hamburger {
        &-header {
          margin-top: 6 / 1920 * 100vw * @factor;

          span {
            &:last-child {
              margin-top: -2 / 1920 * 100vw * @factor;
            }
          }
        }
      }
    }
  }
  .page {
    &-Home {
      .content {
        padding-bottom: 138 / 1920 * 100vw * @factor;
      }
    }
  }
  .triangle {
    border-width: 180 / 1920 * 100vw * @factor 309 / 1920 * 100vw * @factor 0 0;

    .logo {
      width: 167 / 1920 * 100vw * @factor;
      height: 24 / 1920 * 100vw * @factor;
      bottom: 87 / 1920 * 100vw * @factor;
      left: 22 / 1920 * 100vw * @factor;
    }
  }
  .logo {
    width: 258 / 1920 * 100vw * @factor;
    height: 38 / 1920 * 100vw * @factor;
  }
  .content {
    width: 1784 / 1920 * 100vw * @factor;
  }

  header {
    top: 50 / 1920 * 100vw * @factor;
    right: 52 / 1920 * 100vw * @factor;

    a {
      font-size: 18 / 1920 * 100vw * @factor;
      line-height: 18 / 1920 * 100vw * @factor;
      height: 50 / 1920 * 100vw * @factor;
      border-radius: 25 / 1920 * 100vw * @factor;
      padding: 15 / 1920 * 100vw * @factor 18 / 1920 * 100vw * @factor 0;
      margin: 0 1 / 1920 * 100vw * @factor;
    }

    &.hidden {
      top: -100 / 1920 * 100vw * @factor;
    }
  }
  footer {
    font-size: 16 / 1920 * 100vw * @factor;

    a {
      margin: 0 15 / 1920 * 100vw * @factor;
    }

  }
  .menu {
    &-footer {
      a {
        padding: 6 / 1920 * 100vw * @factor 14 / 1920 * 100vw * @factor 0;
        margin: 0 1 / 1920 * 100vw * @factor;
        height: 34 / 1920 * 100vw * @factor;
        border-radius: 17 / 1920 * 100vw * @factor;
      }
    }

    &-additional {
      padding: 0 80 / 1920 * 100vw * @factor 0 40 / 1920 * 100vw * @factor;
      padding: 0;
      a {
        padding: 13 / 1920 * 100vw * @factor 28 / 1920 * 100vw * @factor 0;
        margin: 0 1 / 1920 * 100vw * @factor;
        height: 48 / 1920 * 100vw * @factor;
        border-radius: 24 / 1920 * 100vw * @factor;
      }
    }
  }
  .btn {
    font-size: 38 / 1920 * 100vw * @factor;
    line-height: 38 / 1920 * 100vw * @factor;
    border-radius: 43 / 1920 * 100vw * @factor;
    padding: 20 / 1920 * 100vw * @factor 45 / 1920 * 100vw * @factor 28 / 1920 * 100vw * @factor;
    &-small {
      font-size: 18 / 1920 * 100vw * @factor;
      line-height: 18 / 1920 * 100vw * @factor;
      border-radius: 22 / 1920 * 100vw * @factor;
      padding: 12 / 1920 * 100vw * @factor 20 / 1920 * 100vw * @factor 13 / 1920 * 100vw * @factor;
    }

    &-midi {
      font-size: 17 / 1920 * 100vw * @factor;
      line-height: 17 / 1920 * 100vw * @factor;
      border-radius: 27 / 1920 * 100vw * @factor;
      padding: 18 / 1920 * 100vw * @factor 30 / 1920 * 100vw * @factor 19 / 1920 * 100vw * @factor;
    }

    &-fixed {
      width: 270 / 1920 * 100vw * @factor;
    }

    &-large {
      font-size: 20 / 1920 * 100vw * @factor;
      border-radius: 27 / 1920 * 100vw * @factor;
      padding: 7 / 1920 * 100vw * @factor 30 / 1920 * 100vw * @factor 10 / 1920 * 100vw * @factor;
    }

    .icon {
      margin-left: -5 / 1920 * 100vw * @factor;
      margin-right: 20 / 1920 * 100vw * @factor;
      vertical-align: middle;
    }
  }
  .icon {
    &-tg {
      width: 17 / 1920 * 100vw * @factor;
      height: 14 / 1920 * 100vw * @factor;
    }
  }
  .download {
    bottom: 137 / 1920 * 100vw * @factor;
    right: 68 / 1920 * 100vw * @factor;

    &-left {
      margin-right: -68 / 1920 * 100vw * @factor;
    }
  }
  .number {
    font-size: 1363 / 1920 * 100vw * @factor;
  }
  .pagination {
    bottom: 137 / 1920 * 100vw * @factor;
    height: 155 / 1920 * 100vw * @factor;
    margin-left: 75 / 1920 * 100vw * @factor;

    &__item {
      font-size: 25 / 1920 * 100vw * @factor;
      border-radius: 15 / 1920 * 100vw * @factor;
      width: 38 / 1920 * 100vw * @factor;
      margin-right: 5 / 1920 * 100vw * @factor;
    }
  }
  .navigation {
    bottom: 137 / 1920 * 100vw * @factor;
    right: 68 / 1920 * 100vw * @factor;
    border-radius: 50 / 1920 * 100vw * @factor;
  }
  .prev, .next {
    width: 155 / 1920 * 100vw * @factor;
    height: 155 / 1920 * 100vw * @factor;
    background-size: 25 / 1920 * 100vw * @factor auto;
    border-radius: 0 50 / 1920 * 100vw * @factor 50 / 1920 * 100vw * @factor 0;
  }
  .title {
    font-size: 110 / 1920 * 100vw * @factor;
    margin-bottom: 40 / 1920 * 100vw * @factor;

    &-big {
      font-size: 150 / 1920 * 100vw * @factor;
    }

    &-verybig {
      font-size: 160 / 1920 * 100vw * @factor;
    }
  }
  .text {
    font-size: 26 / 1920 * 100vw * @factor;
  }
  p {
    + p {
      margin-top: 20 / 1920 * 100vw * @factor;
    }
  }
  .steps-page {
    padding-left: 76 / 1920 * 100vw * @factor;
  }
  .social {
    margin-right: 15 / 1920 * 100vw * @factor;
    margin-left: 15 / 1920 * 100vw * @factor;

    &__icon {
      width: 44 / 1920 * 100vw * @factor;
      height: 34 / 1920 * 100vw * @factor;
      background-size: 14 / 1920 * 100vw * @factor 14 / 1920 * 100vw * @factor;
      border-radius: 17 / 1920 * 100vw * @factor;
      margin: 0 8 / 1920 * 100vw * @factor 0 0;
    }
  }
  .map-controls {
    bottom: 68 / 1920 * 100vw * @factor;
    left: 68 / 1920 * 100vw * @factor;

    &__zoom {
      width: 94 / 1920 * 100vw * @factor;
      height: 94 / 1920 * 100vw * @factor;
      font-size: 26 / 1920 * 100vw * @factor;
      line-height: 26 / 1920 * 100vw * @factor;
    }
  }
  .filter {
    top: 147 / 1920 * 100vw * @factor;
    right: (68 - 16) / 1920 * 100vw * @factor;
    box-shadow: 0 0 20 / 1920 * 100vw * @factor fade(@black, 4%);
    padding: 27 / 1920 * 100vw * @factor 25 / 1920 * 100vw * @factor 25 / 1920 * 100vw * @factor;
    border-radius: 43 / 1920 * 100vw * @factor;
    min-width: 400 / 1920 * 100vw * @factor;

    &-tab {
      +.filter-tab {
        padding-top: 25 / 1920 * 100vw * @factor;
      }
    }
  }
  .carousel-block {
    margin: 0 -25 / 1920 * 100vw * @factor -25 / 1920 * 100vw * @factor;
    border-radius: 0 0 43 / 1920 * 100vw * @factor 43 / 1920 * 100vw * @factor;
    height: 230 / 1920 * 100vw * @factor;
    max-width: 400 / 1920 * 100vw * @factor;
  }
  .owl-carousel-popup {
    .owl-stage-outer {
      margin-bottom: -60 / 1920 * 100vw * @factor;
      padding-bottom: 60 / 1920 * 100vw * @factor;
      margin-top: -45 / 1920 * 100vw * @factor;
      padding-top: 45 / 1920 * 100vw * @factor;
    }
  }
  .block{
    font-size: 20 / 1920 * 100vw * @factor;
    padding: 40 / 1920 * 100vw * @factor 70 / 1920 * 100vw * @factor 35 / 1920 * 100vw * @factor;
    &__title{
      font-size: 40 / 1920 * 100vw * @factor; margin-bottom: 20 / 1920 * 100vw * @factor;
    }
  }
  .tariff {
    margin: 22 / 1920 * 100vw * @factor 0 21 / 1920 * 100vw * @factor 20 / 1920 * 100vw * @factor;
    border-radius: 30 / 1920 * 100vw * @factor;
    padding: 18 / 1920 * 100vw * @factor 10 / 1920 * 100vw * @factor 10 / 1920 * 100vw * @factor;
    font-size: 15 / 1920 * 100vw * @factor;
    height: 186 / 1920 * 100vw * @factor;

    &__content {
      padding: 0 13 / 1920 * 100vw * @factor;
    }

    &__price {
      font-size: 22 / 1920 * 100vw * @factor;
      margin-bottom: 14 / 1920 * 100vw * @factor;
    }

    &__wait {
      margin-bottom: 5 / 1920 * 100vw * @factor;
    }
  }
  .car-names {
    margin: 7 / 1920 * 100vw * @factor 0 0 0;
    max-height: 200 / 1920 * 100vw * @factor;
  }
  .car-name {
    font-size: 20 / 1920 * 100vw * @factor;
    margin-right: 20 / 1920 * 100vw * @factor;
    margin-bottom: 8 / 1920 * 100vw * @factor;

    &__name {
      padding: 7 / 1920 * 100vw * @factor 27 / 1920 * 100vw * @factor 8 / 1920 * 100vw * @factor;
      border-radius: 21 / 1920 * 100vw * @factor;
    }

    &__cnt {
      font-size: 16 / 1920 * 100vw * @factor;
    }
  }
  .cars {
    height: 93 / 1920 * 100vw * @factor;

    &:before, &:after {
      width: 52 / 1920 * 100vw * @factor;
    }

    &:after {
      left: 52 / 1920 * 100vw * @factor;
      border-width: 93 / 1920 * 100vw * @factor 0 0 203 / 1920 * 100vw * @factor;
    }

    &-block {
      padding-top: 95 / 1920 * 100vw * @factor;
      margin: 0 -25 / 1920 * 100vw * @factor;
    }
  }
  .car {
    height: 163 / 1920 * 100vw * @factor;
    bottom: 25 / 1920 * 100vw * @factor;
  }
  .fancybox {
    &-close {
      width: 28 / 1920 * 100vw * @factor;
      height: 28 / 1920 * 100vw * @factor;
      top: 35 / 1920 * 100vw * @factor;
      right: 48 / 1920 * 100vw * @factor;
    }

    &-slide--html {
      .fancybox-close-small {
        top: 35 / 1920 * 100vw * @factor;
        right: 48 / 1920 * 100vw * @factor;
      }
    }
  }
  .popup {
    border-radius: 60 / 1920 * 100vw * @factor;
    // width: 930 / 1920 * 100vw * @factor;
    width: 100%;

    &__tab {
      padding: 50 / 1920 * 100vw * @factor 70 / 1920 * 100vw * @factor 10 / 1920 * 100vw * @factor;
      height: 130 / 1920 * 100vw * @factor;
      font-size: 40 / 1920 * 100vw * @factor;

      &__content {
        font-size: 22 / 1920 * 100vw * @factor;
        padding: 45 / 1920 * 100vw * @factor 70 / 1920 * 100vw * @factor 160 / 1920 * 100vw * @factor;
      }
    }

    &__text {
      &-big {
        font-size: 25 / 1920 * 100vw * @factor;
      }
    }

    &__title {
      font-size: 30 / 1920 * 100vw * @factor;
    }

    &__number {
      font-size: 490 / 1920 * 100vw * @factor;
      line-height: 310 / 1920 * 100vw * @factor;
      right: -25 / 1920 * 100vw * @factor;
    }

    &__next, &__prev {
      height: 160 / 1920 * 100vw * @factor;
      font-size: 25 / 1920 * 100vw * @factor;
      padding: 60 / 1920 * 100vw * @factor 75 / 1920 * 100vw * @factor 0;

      &:before {
        height: 60 / 1920 * 100vw * @factor;
        bottom: 100 / 1920 * 100vw * @factor;
      }

      &:after {
        width: 22 / 1920 * 100vw * @factor;
        height: 39 / 1920 * 100vw * @factor;
        bottom: 33 / 1920 * 100vw * @factor;
      }
    }

    &__next {
      &:before {
        border-radius: 0 0 60 / 1920 * 100vw * @factor 0;
      }

      &:after {
        right: 70 / 1920 * 100vw * @factor;
      }
    }

    &__prev {
      &:before {
        border-radius: 0 0 0 60 / 1920 * 100vw * @factor;
      }

      &:after {
        left: 70 / 1920 * 100vw * @factor;
      }
    }
  }
  .half {
    &__text {
      padding-right: 50 / 1920 * 100vw * @factor;
    }

    &-reverse {
      .half {
        &__text {
          padding-left: 75 / 1920 * 100vw * @factor;
        }
      }
    }

    &-about {
      .half {
        &__text {
          font-size: 58 / 1920 * 100vw * @factor;

          &-small {
            font-size: 43 / 1920 * 100vw * @factor;
          }
        }
      }
    }

    &-members {
      .half {
        &__text {
          font-size: 26 / 1920 * 100vw * @factor;
          padding-bottom: 150 / 1920 * 100vw * @factor;
        }
      }
    }

    &-contacts {
      .half {
        &__text {
          font-size: 22 / 1920 * 100vw * @factor;
        }
      }
    }

    &-press {
      .half {
        &__text {
          margin-bottom: 190 / 1920 * 100vw * @factor;
        }
      }
    }

    &-article {
      .half {
        &__text {
          padding-bottom: 30 / 1920 * 100vw * @factor;
        }
      }
    }
  }
  .vertical-tabs {
    margin-left: -18 / 1920 * 100vw * @factor;
    margin-bottom: 30 / 1920 * 100vw * @factor;

    &__content {
      height: 450 / 1920 * 100vw * @factor;
    }
  }
  .vertical-tab {
    font-size: 26 / 1920 * 100vw * @factor;
    padding: 4 / 1920 * 100vw * @factor 18 / 1920 * 100vw * @factor 5 / 1920 * 100vw * @factor;
    margin-bottom: 10 / 1920 * 100vw * @factor;
    border-radius: 20 / 1920 * 100vw * @factor;
  }
  .members {
    bottom: 88 / 1920 * 100vw * @factor;
  }
  .member {
    width: 138 / 1920 * 100vw * @factor;
    height: 138 / 1920 * 100vw * @factor;

    &__name {
      font-size: 46 / 1920 * 100vw * @factor;
      margin-bottom: 30 / 1920 * 100vw * @factor;
    }
  }
  .ps {
    &__container {
      padding-right: 10 / 1920 * 100vw * @factor;
    }
  }
  .hamburger {
    &-header {
      top: 68 / 1920 * 100vw * @factor;
      right: 68 / 1920 * 100vw * @factor;
      width: 28 / 1920 * 100vw * @factor;

      span {
        //height: round(3 / 1920 * 100vw * @factor, 2);

        &:last-child {
          margin-top: 11 / 1920 * 100vw * @factor;
        }
      }

      &.hidden {
        top: -100 / 1920 * 100vw * @factor;
      }
    }
  }
  .contacts-block {
    margin-top: 55 / 1920 * 100vw * @factor;

    &__btns {
      .btn {
        + .btn {
          margin-left: 15 / 1920 * 100vw * @factor;
        }
      }

      &-2 {
        margin-left: -20 / 1920 * 100vw * @factor;
        margin-top: -25 / 1920 * 100vw * @factor;

        .btn {
          margin: 25 / 1920 * 100vw * @factor 0 0 20 / 1920 * 100vw * @factor;

          + .btn {
            margin-left: 20 / 1920 * 100vw * @factor;
          }
        }
      }
    }

    &__title {
      margin-bottom: 50 / 1920 * 100vw * @factor;
    }

    &__text {
      &-small {
        font-size: 18 / 1920 * 100vw * @factor;
        line-height: 30 / 1920 * 100vw * @factor;
      }
    }
  }
  .articles {
    margin-left: -16 / 1920 * 100vw * @factor;
  }
  .article {
    &__link {
      font-size: 60 / 1920 * 100vw * @factor;
      line-height: 55 / 1920 * 100vw * @factor;
      margin-bottom: 40 / 1920 * 100vw * @factor;
      padding: 8 / 1920 * 100vw * @factor 16 / 1920 * 100vw * @factor 13 / 1920 * 100vw * @factor;
    }
    font-size: 22 / 1920 * 100vw * @factor;

    &__title {
      font-size: 46 / 1920 * 100vw * @factor;
      margin-bottom: 30 / 1920 * 100vw * @factor;
    }
  }
  .back {
    margin-left: 75 / 1920 * 100vw * @factor;
    font-size: 25 / 1920 * 100vw * @factor;
    top: 60 / 1920 * 100vw * @factor;
  }
  .ps.ps--active-y{
    margin-right: -20 / 1920 * 100vw * @factor;
    padding-right: 20 / 1920 * 100vw * @factor;
  }
}
